import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

import tempImg from '../../assets/images/add-photo.png';
import LoadingSpinner from '../../component/LoadingSpinner.js';



const NewReview = () =>{
    var [isLoading,setIsLoading] = React.useState(false);
    var [newReview, setNewReview] = React.useState({
     displayImage:'',title:'',star:'',reviewBy:'',country:'',date:'',description:'',make:'',model:''
  })
  const [displayImage,setDisplayImage] = React.useState(tempImg);
  const onImageChange = (event,val) => {
   if (event.target.files && event.target.files[0]) {
    if (val == "diplayImg") {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setNewReview({...newReview,displayImage:event.target.files[0]});
    }
   }
  }
  
      let navigate = useNavigate();
  
  
  function addNewReview(e) {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
  
        if (newReview.displayImage && newReview.description && newReview.title && newReview.model && newReview.make &&
             newReview.country && newReview.reviewBy && newReview.date && newReview.star ) {
            formData.append("data",JSON.stringify(newReview));
            formData.append("files",newReview.displayImage);
           setTimeout(() => {    
             axios.post('https://backend.kbmjapan.net/add-review',formData,{withCredentials:true})
              .then((res)=>{
                if (res.status == 200) {            
                  setIsLoading(false);
                     navigate('/');
                     window.location.reload();
                }
              })
              .catch((err)=>{
                console.log(err);
                setIsLoading(false);
              })
           }, 100);
        }else{
          setIsLoading(false);
          document.getElementById('newproductUpdateTxt').innerText = 'kindly fill the form properly...';
          setTimeout(() => {
            document.getElementById('newproductUpdateTxt').innerText = '';
          }, 2500);
        }
      
  }
  
      return(<>
      { isLoading ? (<LoadingSpinner/>):(
        <div><div className="page-head">
   <h2 className="text-center">add review</h2>   
   </div>
      <div className="row w-95 m-auto mt-5">
    <div className="col-6">
    <div className="col-12 text-left mb-3">
      <label className="checkout-field-label d-block" for="productname">Title<span className="important-star">*</span></label>
      <input type="text" className="checkout-input-field w-95" value={newReview.title} onChange={(e)=>setNewReview({...newReview,title:e.target.value})}   name="title" />
      </div>
    <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="price">By<span className="important-star">*</span></label>
      <input type="text" className="checkout-input-field w-95" value={newReview.reviewBy} onChange={(e)=>setNewReview({...newReview,reviewBy:e.target.value})}  name="By"/>
      </div>
      <div className="col-12 text-left mb-3">
      
      <label className="checkout-field-label d-block" for="price">Make<span className="important-star">*</span></label>
      <select name="make" id="make"   aria-label="make" value={newReview.make} onChange={(e)=>setNewReview({...newReview,make:e.target.value})} style={{width:'95%'}}>
                              <option value="">Select</option>
                              
                              <option value="TOYOTA">TOYOTA</option>
  <option value="NISSAN">NISSAN</option>
  <option value="HONDA">HONDA</option>
  <option value="MITSUBISHI">MITSUBISHI</option>
  <option value="SUBARU">SUBARU</option>
  <option value="MAZDA">MAZDA</option>
  <option value="SUZUKI">SUZUKI</option>
  <option value="ISUZU">ISUZU</option>
  <option value="DAIHATSU">DAIHATSU</option>
  <option value="HINO">HINO</option>
  <option value="UD TRUCKS">UD TRUCKS</option>
  <option value="LEXUS">LEXUS</option>
  <option value="MERCEDES-BENZ">MERCEDES-BENZ</option>
  <option value="BMW">BMW</option>
  <option value="LAND ROVER">LAND ROVER</option>
  <option value="ROVER">ROVER</option>
  <option value="JEEP">JEEP</option>
  <option value="VOLKSWAGEN">VOLKSWAGEN</option>
  <option value="AUDI">AUDI</option>
  <option value="CHEVROLET">CHEVROLET</option>
  <option value="VOLVO">VOLVO</option>
  <option value="FORD">FORD</option>
  <option value="JAGUAR">JAGUAR</option>
  <option value="OPEL">OPEL</option>
  <option value="TESLA">TESLA</option>
  <option value="HYUNDAI">HYUNDAI</option>
  <option value="KIA">KIA</option>
  <option value="DAEWOO">DAEWOO (Chevrolet)</option>
  <option value="ABARTH">ABARTH</option>
  <option value="Acura">Acura</option>
  <option value="AIRMAN">AIRMAN</option>
  <option value="ALFA ROMEO">ALFA ROMEO</option>
  <option value="ALPINE">ALPINE</option>
  <option value="AMG">AMG</option>
  <option value="Aston Martin">Aston Martin</option>
  <option value="BAIC">BAIC</option>
  <option value="BAIC Yinxiang">BAIC Yinxiang</option>
  <option value="Beijing Automobile Works (BAW)">Beijing Automobile Works (BAW)</option>
  <option value="Beijing Hyundai">Beijing Hyundai</option>
  <option value="BENTLEY">BENTLEY</option>
  <option value="BMW Alpina">BMW Alpina</option>
  <option value="Buick">Buick</option>
  <option value="BYD">BYD</option>
  <option value="CADILLAC">CADILLAC</option>
  <option value="CASE">CASE</option>
  <option value="CAT">CAT</option>
  <option value="Caterham">Caterham</option>
  <option value="Caterpillar">Caterpillar</option>
  <option value="CHANGAN">CHANGAN</option>
  <option value="ChangAn Automobile">ChangAn Automobile</option>
  <option value="Chery">Chery</option>
  <option value="CHRYSLER">CHRYSLER</option>
  <option value="CITROEN">CITROEN</option>
  <option value="Daf">Daf</option>
  <option value="DFCV">DFCV</option>
  <option value="DFM (Dongfeng Motor)">DFM (Dongfeng Motor)</option>
  <option value="Dodge">Dodge</option>
  <option value="Dongfeng Peugeot Citroen Automobile">Dongfeng Peugeot Citroen Automobile</option>
  <option value="Doosan">Doosan</option>
  <option value="DS AUTOMOBILES">DS AUTOMOBILES</option>
  <option value="FAW">FAW</option>
  <option value="FERRARI">FERRARI</option>
  <option value="Fiat">Fiat</option>
  <option value="Foton">Foton</option>
  <option value="France Others">France Others</option>
  <option value="Geely">Geely</option>
  <option value="Genesis">Genesis</option>
  <option value="GMC">GMC</option>
  <option value="Great Wall">Great Wall</option>
  <option value="HAVAL">HAVAL</option>
  <option value="Hitachi">Hitachi</option>
  <option value="HOWO">HOWO</option>
  <option value="HOZON">HOZON</option>
  <option value="HUMMER">HUMMER</option>
  <option value="HYSTER">HYSTER</option>
  <option value="IM">IM</option>
  <option value="Infiniti">Infiniti</option>
  <option value="Iveco">Iveco</option>
  <option value="JAC">JAC</option>
  <option value="JCB">JCB</option>
  <option value="JETOUR">JETOUR</option>
  <option value="KING LONG">KING LONG</option>
  <option value="Kobelco">Kobelco</option>
  <option value="Komatsu">Komatsu</option>
  <option value=">Korea Others">Korea Others</option>
  <option value="Lamborghini">Lamborghini</option>
  <option value="Lancia">Lancia</option>
  <option value="Li Auto">Li Auto</option>
  <option value="Lincoln">Lincoln</option>
  <option value="Lorenz lancle">Lorenz&amp;lancle</option>
  <option value="Lotus">Lotus</option>
  <option value="MAN">MAN</option>
  <option value="Maserati">Maserati</option>
  <option value="Massey Ferguson">Massey Ferguson</option>
  <option value="Maybach">Maybach</option>
  <option value="MCC">MCC</option>
  <option value="McLaren">McLaren</option>
  <option value="MG">MG</option>
  <option value="Mini">Mini</option>
  <option value="448">MITSUBISHI</option>
  <option value="MITSUBISHI Fuso">Mitsubishi Fuso</option>
  <option value="MITSUBISHI Logisnext">MITSUBISHI Logisnext</option>
  <option value="MITSUBISHI NICHIYU">MITSUBISHI NICHIYU</option>
  <option value="Mitsuoka">Mitsuoka</option>
  <option value="Nichiyu">Nichiyu</option>
  <option value="NISSAN">NISSAN</option>
  <option value="Other All">Other All</option>
  <option value="PEUGEOT">PEUGEOT</option>
  <option value="Polestar">Polestar</option>
  <option value="Porsche">Porsche</option>
  <option value="RADAR">RADAR</option>
  <option value="RAM">RAM</option>
  <option value="Renault">Renault</option>
  <option value="Renault Samsung">Renault Samsung</option>
  <option value="Rolls-Royce">Rolls-Royce</option>
  <option value="SAAB">SAAB</option>
  <option value="Samsung">Samsung</option>
  <option value="Scania">Scania</option>
  <option value="SEAT">SEAT</option>
  <option value="Skoda">Skoda</option>
  <option value="Smart">Smart</option>
  <option value="Soueast Motor">Soueast Motor</option>
  <option value="Ssangyong">Ssangyong</option>
  <option value="Sumitomo">Sumitomo</option>
  <option value="SUMITOMO NACCO">SUMITOMO NACCO</option>
  <option value="Sunlong">Sunlong</option>
  <option value="Sweden Others">Sweden Others</option>
  <option value="TAKEUCHI">TAKEUCHI</option>
  <option value="TCM">TCM</option>
  <option value="TIFFIN">TIFFIN</option>
  <option value="TOYOTA">TOYOTA</option>
  <option value="Triumph">Triumph</option>
  <option value="TRUMPCHI">TRUMPCHI</option>
  <option value="U.SA. Others">U.SA. Others</option>
  <option value="UNICARRIERS">UNICARRIERS</option>
  <option value="VOYAH">VOYAH</option>
  <option value="WULING">WULING</option>
  <option value="XCMG">XCMG</option>
  <option value="XPENG">XPENG</option>
  <option value="YANMAR">YANMAR</option>
  <option value="ZEEKR">ZEEKR</option>
  <option value="DAIMLER">DAIMLER</option>
  <option value="ISUZU">ISUZU</option>
  <option value="KUBOTA">KUBOTA</option>
  <option value="FORD">FORD</option>
  <option value="467">Others</option>
  <option value="BOBCAT">BOBCAT</option>
  <option value="HALLA">HALLA</option>
  <option value="John Deere">John Deere</option>
  <option value="NEWHOLLAND">NEWHOLLAND</option>
  
                          </select>
        </div>
        <div className="col-12 text-left mb-3" >
      
      <label className="checkout-field-label d-block" for="price">Model<span className="important-star">*</span></label>
      <select name="model" id="model" value={newReview.model} onChange={(e)=>setNewReview({...newReview,model:e.target.value})} aria-label="model" style={{width:'95%'}}>
                              <option value="">Select</option>
                              <option value="001">001</option>
  <option value="009">009</option>
  <option value="02-8FD25">02-8FD25</option>
  <option value="02-8FD30">02-8FD30</option>
  <option value="02-8FG10">02-8FG10</option>
  <option value="02-8FG15">02-8FG15</option>
  <option value="02-8FGL10">02-8FGL10</option>
  <option value="02-8FGL15">02-8FGL15</option>
  <option value="02-8FGL20">02-8FGL20</option>
  <option value="1 SERIES ">1 SERIES </option>
  <option value="1007">1007</option>
  <option value="106">106</option>
  <option value="114">114</option>
  <option value="117 COUPE">117 COUPE</option>
  <option value="124">124 </option>
  <option value="143">143</option>
  <option value="144">144 </option>
  <option value="146">146 </option>
  <option value="147">147 </option>
  <option value="1500">1500 </option>
  <option value="155">155 </option>
  <option value="156">156 </option>
  <option value="156 SPORTWAGON">156 SPORTWAGON </option>
  <option value="159">159 </option>
  <option value="159 SPORTWAGON">159 SPORTWAGON </option>
  <option value="164">164 </option>
  <option value="166">166 </option>
  <option value="18">18 </option>
  <option value="180SX">180SX </option>
  <option value="190 CLASS">190 CLASS </option>
  <option value="1M">1M </option>
  <option value="2 SERIES">2 SERIES </option>
  <option value="200">200 </option>
  <option value="2008">2008 </option>
  <option value="205">205 </option>
  <option value="206">206 </option>
  <option value="207">207 </option>
  <option value="208">208 </option>
  <option value="222">222 </option>
  <option value="240">240 </option>
  <option value="250">250 </option>
  <option value="2500">2500 </option>
  <option value="3 SERIES">3 SERIES </option>
  <option value="3 SERIES CABRIORET ">3 SERIES CABRIORET </option>
  <option value="300">300 </option>
  <option value="300 SERIES ">300 SERIES </option>
  <option value="3008">3008 </option>
  <option value="300C">300C </option>
  <option value="306">306 </option>
  <option value="307">307 </option>
  <option value="308">308 </option>
  <option value="3200GT">3200GT </option>
  <option value="320D">320D </option>
  <option value="328">328 </option>
  <option value="348">348 </option>
  <option value="350Z">350Z </option>
  <option value="360 MODENA">360 MODENA </option>
  <option value="4 SERIES">4 SERIES </option>
  <option value="40-7FG15 ">40-7FG15 </option>
  <option value="406 ">406 </option>
  <option value="407">407 </option>
  <option value="430 SCUDERIA ">430 SCUDERIA </option>
  <option value="456M">456M </option>
  <option value="4RUNNER">4RUNNER </option>
  <option value="5 SERIES">5 SERIES </option>
  <option value="50-8FD25">50-8FD25 </option>
  <option value="500">500 </option>
  <option value="500 SERIES">500 SERIES </option>
  <option value="5008">5008 </option>
  <option value="500X">500X </option>
  <option value="508">508 </option>
  <option value="595">595 </option>
  <option value="599">599 </option>
  <option value="6 SERIES">6 SERIES </option>
  <option value="612 SCAGLIETTI">612 SCAGLIETTI </option>
  <option value="61FBR13NXII">61FBR13NXII </option>
  <option value="62">62 </option>
  <option value="7 SERIES">7 SERIES </option>
  <option value="700 SERIES">700 SERIES </option>
  <option value="740 760">740 760 </option>
  <option value="7FB10">7FB10 </option>
  <option value="7FB15">7FB15 </option>
  <option value="7FB20">7FB20 </option>
  <option value="7FBH15">7FBH15 </option>
  <option value="7FBL15">7FBL15 </option>
  <option value="7FBR13">7FBR13 </option>
  <option value="7FBR15">7FBR15 </option>
  <option value="7FBRK10">7FBRK10 </option>
  <option value="7FBRK9">7FBRK9 </option>
  <option value="7FBRS15">7FBRS15 </option>
  <option value="7FD45">7FD45 </option>
  <option value="7FG15">7FG15 </option>
  <option value="7FGL14">7FGL14 </option>
  <option value="8 SERIES">8 SERIES </option>
  <option value="80">80 </option>
  <option value="850">850 </option>
  <option value="850 ESTATE">850 ESTATE </option>
  <option value="85CF">85CF </option>
  <option value="86">86 </option>
  <option value="8C">8C </option>
  <option value="8FB10">8FB10 </option>
  <option value="8FB15">8FB15 </option>
  <option value="8FBE15">8FBE15 </option>
  <option value="8FGL15">8FGL15 </option>
  <option value="9-3 SERIES">9-3 SERIES </option>
  <option value="9-5 SERIES">9-5 SERIES </option>
  <option value="900">900 </option>
  <option value="911">911 </option>
  <option value="94">94 </option>
  <option value="940">940 </option>
  <option value="940 ESTATE">940 ESTATE </option>
  <option value="944">944 </option>
  <option value="960">960 </option>
  <option value="A-CLASS">A-CLASS </option>
  <option value="A-CLASS SEDAN">A-CLASS SEDAN </option>
  <option value="A1">A1 </option>
  <option value="A110">A110 </option>
  <option value="A3">A3 </option>
  <option value="A3 SEDAN">A3 SEDAN </option>
  <option value="A4">A4 </option>
  <option value="A4 ALLROAD QUATTRO">A4 ALLROAD QUATTRO </option>
  <option value="A5">A5 </option>
  <option value="A6">A6 </option>
  <option value="A6 ALLROAD QUATTRO ">A6 ALLROAD QUATTRO </option>
  <option value="A7">A7 </option>
  <option value="A8">A8 </option>
  <option value="ABARTH OTHERS">ABARTH OTHERS </option>
  <option value="ACADIA">ACADIA </option>
  <option value="ACCENT">ACCENT </option>
  <option value="ACCORD">ACCORD </option>
  <option value="ACCORD COUPE">ACCORD COUPE </option>
  <option value="ACCORD HYBRID ">ACCORD HYBRID </option>
  <option value="ACCORD TOURER">ACCORD TOURER </option>
  <option value="ACCORD WAGON">ACCORD WAGON </option>
  <option value="ACTROS">ACTROS </option>
  <option value="ACTY">ACTY </option>
  <option value="ACTY TRUCK">ACTY TRUCK </option>
  <option value="ACTY VAN">ACTY VAN </option>
  <option value="ACTYON">ACTYON </option>
  <option value="ACTYON SPORTS">ACTYON SPORTS </option>
  <option value="AD EXPERT">AD EXPERT </option>
  <option value="AD VAN">AD VAN </option>
  <option value="AERIO SEDAN ">AERIO SEDAN </option>
  <option value="AERO ACE">AERO ACE </option>
  <option value="AERO BUS">AERO BUS </option>
  <option value="AERO MIDI">AERO MIDI </option>
  <option value="AERO MIDI">AERO MIDI </option>
  <option value="AERO QUEEN ">AERO QUEEN </option>
  <option value="AERO STAR">AERO STAR </option>
  <option value="AERO TOWN">AERO TOWN </option>
  <option value="AIR EV 2 SEATS">AIR EV 2 SEATS </option>
  <option value="AIR EV 4 SEATS ">AIR EV 4 SEATS </option>
  <option value="AIRMAN OTHERS">AIRMAN OTHERS </option>
  <option value="AIRTREK">AIRTREK </option>
  <option value="AIRWAVE">AIRWAVE </option>
  <option value="ALCYONE">ALCYONE </option>
  <option value="ALCYONE SVX">ALCYONE SVX </option>
  <option value="ALFA ROMEO OTHERS">ALFA ROMEO OTHERS </option>
  <option value="ALL NEW AVANTE">ALL NEW AVANTE </option>
  <option value="ALL NEW AVANTE N LINE">ALL NEW AVANTE N LINE </option>
  <option value="ALL NEW CARNIVAL">ALL NEW CARNIVAL </option>
  <option value="ALL NEW K3">ALL NEW K3 </option>
  <option value="ALL NEW K7 ">ALL NEW K7 </option>
  <option value="ALL NEW MALIBU">ALL NEW MALIBU </option>
  <option value="ALL NEW MORNING ">ALL NEW MORNING </option>
  <option value="ALL NEW MORNING (JA)">ALL NEW MORNING (JA) </option>
  <option value="ALL NEW SORENTO">ALL NEW SORENTO </option>
  <option value="ALL NEW TUCSON">ALL NEW TUCSON </option>
  <option value="ALLEX">ALLEX </option>
  <option value="ALLION">ALLION </option>
  <option value="ALLROAD QUATTRO">ALLROAD QUATTRO </option>
  <option value="ALPHARD">ALPHARD </option>
  <option value="ALPHARD HYBRID">ALPHARD HYBRID </option>
  <option value="ALPHEON">ALPHEON </option>
  <option value="ALPINE">ALPINE </option>
  <option value="ALTEZZA">ALTEZZA </option>
  <option value="ALTEZZA GITA ">ALTEZZA GITA </option>
  <option value="ALTIMA">ALTIMA </option>
  <option value="ALTIS">ALTIS </option>
  <option value="ALTO">ALTO </option>
  <option value="ALTO WORKS">ALTO WORKS </option>
  <option value="AMAROK">AMAROK </option>
  <option value="AMG GT">AMG GT </option>
  <option value="AMG OTHERS">AMG OTHERS </option>
  <option value="AQUA">AQUA </option>
  <option value="ARISTO">ARISTO </option>
  <option value="ARMADA">ARMADA </option>
  <option value="ARNAGE">ARNAGE </option>
  <option value="ARONA">ARONA </option>
  <option value="ARTEON">ARTEON </option>
  <option value="ASKA">ASKA </option>
  <option value="ASLAN">ASLAN </option>
  <option value="ASPIRE">ASPIRE </option>
  <option value="ASTON MARTIN OTHERS">ASTON MARTIN OTHERS </option>
  <option value="ASTRA">ASTRA </option>
  <option value="ASTRA WAGON">ASTRA WAGON </option>
  <option value="ASTRO">ASTRO </option>
  <option value="ASX">ASX </option>
  <option value="ATECA">ATECA </option>
  <option value="ATEGO">ATEGO </option>
  <option value="ATENZA">ATENZA </option>
  <option value="ATENZA SPORT">ATENZA SPORT </option>
  <option value="ATENZA SPORT WAGON">ATENZA SPORT WAGON </option>
  <option value="ATENZA WAGON ">ATENZA WAGON </option>
  <option value="ATLAS">ATLAS </option>
  <option value="ATLAS LOCO ">ATLAS LOCO </option>
  <option value="ATRAI">ATRAI </option>
  <option value="ATRAI 7">ATRAI 7 </option>
  <option value="ATRAI WAGON">ATRAI WAGON </option>
  <option value="ATS">ATS </option>
  <option value="ATTITUDE">ATTITUDE </option>
  <option value="AUDI OTHERS">AUDI OTHERS </option>
  <option value="AURA">AURA </option>
  <option value="AURIS">AURIS </option>
  <option value="AVALANCHE">AVALANCHE </option>
  <option value="AVALON">AVALON </option>
  <option value="AVANCIER">AVANCIER </option>
  <option value="AVANTE (ELANTRA)">AVANTE (ELANTRA) </option>
  <option value="AVANTE AD ">AVANTE AD </option>
  <option value="AVANTIME">AVANTIME </option>
  <option value="AVANZA">AVANZA </option>
  <option value="AVENGER">AVENGER </option>
  <option value="AVENIR">AVENIR </option>
  <option value="AVENIRCARGO">AVENIRCARGO </option>
  <option value="AVENSIS">AVENSIS </option>
  <option value="AVENSIS WAGON">AVENSIS WAGON </option>
  <option value="AVEO">AVEO </option>
  <option value="AX">AX </option>
  <option value="AXELA">AXELA </option>
  <option value="AXELA HYBRID">AXELA HYBRID </option>
  <option value="AXELA SPORT">AXELA SPORT </option>
  <option value="AXOR">AXOR </option>
  <option value="AZ-1">AZ-1 </option>
  <option value="AZ-OFFROAD">AZ-OFFROAD </option>
  <option value="AZ-WAGON">AZ-WAGON </option>
  <option value="B-CLASS">B-CLASS </option>
  <option value="B-SERIES ">B-SERIES </option>
  <option value="B10">B10 </option>
  <option value="B12">B12 </option>
  <option value="B3">B3 </option>
  <option value="B3 BITURBO">B3 BITURBO </option>
  <option value="B4">B4 </option>
  <option value="B6">B6 </option>
  <option value="B7">B7 </option>
  <option value="BALENO">BALENO </option>
  <option value="BARCHETTA">BARCHETTA </option>
  <option value="BB">BB </option>
  <option value="BE-1 ">BE-1 </option>
  <option value="BE-GO ">BE-GO </option>
  <option value="BEAT">BEAT </option>
  <option value="BEETLE">BEETLE </option>
  <option value="BEIJING AUTOMOBILE WORKS OTHERS">BEIJING AUTOMOBILE WORKS OTHERS </option>
  <option value="BELTA">BELTA </option>
  <option value="BENBEN">BENBEN </option>
  <option value="BENTAYGA">BENTAYGA </option>
  <option value="BENTLEY OTHERS">BENTLEY OTHERS </option>
  <option value="BERLINGO">BERLINGO </option>
  <option value="BH090">BH090 </option>
  <option value="BIANTE">BIANTE </option>
  <option value="BIG DOG ">BIG DOG </option>
  <option value="BIG THUMB">BIG THUMB </option>
  <option value="BIGHORN">BIGHORN </option>
  <option value="BINGO">BINGO </option>
  <option value="BLADE">BLADE </option>
  <option value="BLAZER">BLAZER </option>
  <option value="BLIZZARD">BLIZZARD </option>
  <option value="BLUE RIBBON">BLUE RIBBON </option>
  <option value="BLUEBIRD">BLUEBIRD </option>
  <option value="BLUEBIRD SYLPHY ">BLUEBIRD SYLPHY </option>
  <option value="BMW ALPINA OTHERS">BMW ALPINA OTHERS </option>
  <option value="BMW OTHERS">BMW OTHERS </option>
  <option value="BONGO">BONGO </option>
  <option value="BONGO 3">BONGO 3 </option>
  <option value="BONGO 3 1.2 TON">BONGO 3 1.2 TON </option>
  <option value="BONGO 3 COACH ">BONGO 3 COACH </option>
  <option value="BONGO BRAWNY TRUCK">BONGO BRAWNY TRUCK </option>
  <option value="BONGO BRAWNY VAN">BONGO BRAWNY VAN </option>
  <option value="BONGO FRIENDEE ">BONGO FRIENDEE </option>
  <option value="BONGO TRUCK ">BONGO TRUCK </option>
  <option value="BONGO VAN">BONGO VAN </option>
  <option value="BONGO WAGON ">BONGO WAGON </option>
  <option value="BOON">BOON </option>
  <option value="BOON LUMINAS">BOON LUMINAS </option>
  <option value="BORA">BORA </option>
  <option value="BOXSTER">BOXSTER </option>
  <option value="BRAVO">BRAVO </option>
  <option value="BRERA">BRERA </option>
  <option value="BREVIS">BREVIS </option>
  <option value="BRONCO">BRONCO </option>
  <option value="BROOKLANDS">BROOKLANDS </option>
  <option value="BRZ">BRZ </option>
  <option value="BS090">BS090 </option>
  <option value="BS106">BS106 </option>
  <option value="BT-50">BT-50 </option>
  <option value="BUDDY">BUDDY </option>
  <option value="BUICK OTHERS">BUICK OTHERS </option>
  <option value="BX">BX </option>
  <option value="C-1500">C-1500 </option>
  <option value="C-CLASS">C-CLASS </option>
  <option value="C-CLASS">C-CLASS </option>
  <option value="C-HR">C-HR </option>
  <option value="C-QUATRE ">C-QUATRE </option>
  <option value="C1">C1 </option>
  <option value="C2">C2 </option>
  <option value="C3">C3 </option>
  <option value="C3 PLURIEL ">C3 PLURIEL </option>
  <option value="C30">C30 </option>
  <option value="C4">C4 </option>
  <option value="C4 PICASSO ">C4 PICASSO </option>
  <option value="C5">C5 </option>
  <option value="C6">C6 </option>
  <option value="C70">C70 </option>
  <option value="CABRIO">CABRIO </option>
  <option value="CABRIOLET">CABRIOLET </option>
  <option value="CABSTAR">CABSTAR </option>
  <option value="CADDY">CADDY </option>
  <option value="CADILLAC OTHERS">CADILLAC OTHERS </option>
  <option value="CALDINA">CALDINA </option>
  <option value="CALIBER">CALIBER </option>
  <option value="CALIBRA">CALIBRA </option>
  <option value="CAMARO">CAMARO </option>
  <option value="CAMI">CAMI </option>
  <option value="CAMROAD">CAMROAD </option>
  <option value="CAMRY">CAMRY </option>
  <option value="CAMRY GRACIA">CAMRY GRACIA </option>
  <option value="CAMRY HYBRID">CAMRY HYBRID </option>
  <option value="CANTER">CANTER </option>
  <option value="CANTER">CANTER </option>
  <option value="CANTER GUTS">CANTER GUTS </option>
  <option value="CANTER GUTS">CANTER GUTS </option>
  <option value="CAPA">CAPA </option>
  <option value="CAPELLA WAGON ">CAPELLA WAGON </option>
  <option value="CAPPUCCINO">CAPPUCCINO </option>
  <option value="CAPRICE">CAPRICE </option>
  <option value="CAPTIVA">CAPTIVA </option>
  <option value="CAPTUR">CAPTUR </option>
  <option value="CARA">CARA </option>
  <option value="CARAVAN BUS">CARAVAN BUS </option>
  <option value="CARAVAN COACH">CARAVAN COACH </option>
  <option value="CARAVAN ELGRAND ">CARAVAN ELGRAND </option>
  <option value="CARAVAN VAN">CARAVAN VAN </option>
  <option value="CARAVAN WAGON ">CARAVAN WAGON </option>
  <option value="CARAVELLE">CARAVELLE </option>
  <option value="CARENS">CARENS </option>
  <option value="CARGO">CARGO </option>
  <option value="CARGO">CARGO </option>
  <option value="CARINA">CARINA </option>
  <option value="CARINA ED">CARINA ED </option>
  <option value="CARINA VAN ">CARINA VAN </option>
  <option value="CARNIVAL">CARNIVAL </option>
  <option value="CAROL">CAROL </option>
  <option value="CARRY TRUCK">CARRY TRUCK </option>
  <option value="CARRY VAN ">CARRY VAN </option>
  <option value="CASE OTHERS">CASE OTHERS </option>
  <option value="CASPER">CASPER </option>
  <option value="CAST">CAST </option>
  <option value="CAT OTHERS">CAT OTHERS </option>
  <option value="CATERHAM OTHERS">CATERHAM OTHERS </option>
  <option value="CATERPILLAR OTHERS">CATERPILLAR OTHERS </option>
  <option value="CAVALIER">CAVALIER </option>
  <option value="CAYENNE">CAYENNE </option>
  <option value="CAYMAN">CAYMAN </option>
  <option value="CC">CC </option>
  <option value="CEDRIC CIMA ">CEDRIC CIMA </option>
  <option value="CEDRIC HARDTOP ">CEDRIC HARDTOP </option>
  <option value="CEDRIC SEDAN ">CEDRIC SEDAN </option>
  <option value="CEDRIC VAN">CEDRIC VAN </option>
  <option value="CEDRIC WAGON">CEDRIC WAGON </option>
  <option value="CEFIRO">CEFIRO </option>
  <option value="CELICA">CELICA </option>
  <option value="CELICA XX">CELICA XX </option>
  <option value="CELSIOR">CELSIOR </option>
  <option value="CENTURY">CENTURY </option>
  <option value="CERVO">CERVO </option>
  <option value="CERVO MODE ">CERVO MODE </option>
  <option value="CF">CF </option>
  <option value="CF65">CF65 </option>
  <option value="CF75">CF75 </option>
  <option value="CF85">CF85 </option>
  <option value="CHAIRMAN">CHAIRMAN </option>
  <option value="CHALLENGER">CHALLENGER </option>
  <option value="CHALLENGER">CHALLENGER </option>
  <option value="CHANGAN AUTOMOBILE OTHERS">CHANGAN AUTOMOBILE OTHERS </option>
  <option value="CHARADE">CHARADE </option>
  <option value="CHARGER">CHARGER </option>
  <option value="CHARIOT">CHARIOT </option>
  <option value="CHARIOT GRANDIS ">CHARIOT GRANDIS </option>
  <option value="CHARMANT">CHARMANT </option>
  <option value="CHASER">CHASER </option>
  <option value="CHEROKEE">CHEROKEE </option>
  <option value="CHERY AUTOMOBILE OTHERS">CHERY AUTOMOBILE OTHERS </option>
  <option value="CHEVROLET OTHERS">CHEVROLET OTHERS </option>
  <option value="CHEVY">CHEVY </option>
  <option value="CHIFFON">CHIFFON </option>
  <option value="CHRYSLER OTHERS">CHRYSLER OTHERS </option>
  <option value="CIMA">CIMA </option>
  <option value="CIMA HYBRID">CIMA HYBRID </option>
  <option value="CITROEN OTHERS">CITROEN OTHERS </option>
  <option value="CITY">CITY </option>
  <option value="CITY PRO">CITY PRO </option>
  <option value="CIVIC">CIVIC </option>
  <option value="CIVIC COUPE">CIVIC COUPE </option>
  <option value="CIVIC FERIO ">CIVIC FERIO </option>
  <option value="CIVIC HYBRID">CIVIC HYBRID </option>
  <option value="CIVIC PRO">CIVIC PRO </option>
  <option value="CIVIC SHUTTLE">CIVIC SHUTTLE </option>
  <option value="CIVIC TYPE R ">CIVIC TYPE R </option>
  <option value="CIVILIAN BUS ">CIVILIAN BUS </option>
  <option value="CL-CLASS ">CL-CLASS </option>
  <option value="CLA-CLASS">CLA-CLASS </option>
  <option value="CLICK">CLICK </option>
  <option value="CLIO">CLIO </option>
  <option value="CLIPPER">CLIPPER </option>
  <option value="CLIPPER RIO">CLIPPER RIO </option>
  <option value="CLIPPER TRUCK">CLIPPER TRUCK </option>
  <option value="CLIPPER VAN">CLIPPER VAN </option>
  <option value="CLK-CLASS">CLK-CLASS </option>
  <option value="CLS-CLASS">CLS-CLASS </option>
  <option value="CLUBMAN">CLUBMAN </option>
  <option value="COASTER">COASTER </option>
  <option value="COASTER BIG VAN ">COASTER BIG VAN </option>
  <option value="COASTER R">COASTER R </option>
  <option value="COLORADO">COLORADO </option>
  <option value="COLT">COLT </option>
  <option value="COLTPLUS">COLTPLUS </option>
  <option value="COMBI">COMBI </option>
  <option value="COMBO">COMBO </option>
  <option value="COMFORT">COMFORT </option>
  <option value="COMMANDER">COMMANDER </option>
  <option value="COMO">COMO </option>
  <option value="COMO MICROBUS">COMO MICROBUS </option>
  <option value="COMPASS">COMPASS </option>
  <option value="CONCERT">CONCERT </option>
  <option value="CONCOURS">CONCOURS </option>
  <option value="CONDOR">CONDOR </option>
  <option value="CONTINENTAL">CONTINENTAL </option>
  <option value="CONTINENTAL FLYING SPUR">CONTINENTAL FLYING SPUR </option>
  <option value="CONTINENTAL GT">CONTINENTAL GT </option>
  <option value="COO">COO </option>
  <option value="COOLRAY">COOLRAY </option>
  <option value="COOPER">COOPER </option>
  <option value="COOPER COUPE">COOPER COUPE </option>
  <option value="COPEN">COPEN </option>
  <option value="COROLLA">COROLLA </option>
  <option value="COROLLA ALTIS">COROLLA ALTIS </option>
  <option value="COROLLA AXIO">COROLLA AXIO </option>
  <option value="COROLLA CROSS ">COROLLA CROSS </option>
  <option value="COROLLA FIELDER ">COROLLA FIELDER </option>
  <option value="COROLLA II">COROLLA II </option>
  <option value="COROLLA LEVIN">COROLLA LEVIN </option>
  <option value="OROLLA RUMION">COROLLA RUMION </option>
  <option value="COROLLA RUNX">COROLLA RUNX </option>
  <option value="COROLLA SEDAN">COROLLA SEDAN </option>
  <option value="COROLLA SPACIO">COROLLA SPACIO </option>
  <option value="COROLLA SPORT">COROLLA SPORT </option>
  <option value="COROLLA TOURING">COROLLA TOURING </option>
  <option value="COROLLA TOURING WAGON">COROLLA TOURING WAGON </option>
  <option value="COROLLA VAN ">COROLLA VAN </option>
  <option value="COROLLA WAGON">COROLLA WAGON </option>
  <option value="CORONA">CORONA </option>
  <option value="CORONA COUPE">CORONA COUPE </option>
  <option value="CORONA EXIV ">CORONA EXIV </option>
  <option value="CORONA PREMIO">CORONA PREMIO </option>
  <option value="CORONA VAN">CORONA VAN </option>
  <option value="CORSA">CORSA </option>
  <option value="CORVETTE">CORVETTE </option>
  <option value="COSMO">COSMO </option>
  <option value="COUNTRYMAN">COUNTRYMAN </option>
  <option value="COUNTY">COUNTY </option>
  <option value="COUPE">COUPE </option>
  <option value="CR-V">CR-V </option>
  <option value="CR-X">CR-X </option>
  <option value="CR-X DELSOL">CR-X DELSOL </option>
  <option value="CR-Z">CR-Z </option>
  <option value="CREDOS">CREDOS </option>
  <option value="CRESTA">CRESTA </option>
  <option value="CRETA">CRETA </option>
  <option value="CREW">CREW </option>
  <option value="CROSS COUNTRY">CROSS COUNTRY </option>
  <option value="CROSS GOLF">CROSS GOLF </option>
  <option value="CROSS POLO">CROSS POLO </option>
  <option value="CROSSFIRE">CROSSFIRE </option>
  <option value="CROSSFOX">CROSSFOX </option>
  <option value="CROSSROAD">CROSSROAD </option>
  <option value="CROWN">CROWN </option>
  <option value="CROWN ATHLETE SERIES">CROWN ATHLETE SERIES </option>
  <option value="CROWN COMFORT">CROWN COMFORT </option>
  <option value="CROWN CROSSOVER">CROWN CROSSOVER </option>
  <option value="CROWN ESTATE">CROWN ESTATE </option>
  <option value="CROWN HARDTOP">CROWN HARDTOP </option>
  <option value="CROWN HYBRID">CROWN HYBRID </option>
  <option value="CROWN MAJESTA">CROWN MAJESTA </option>
  <option value="CROWN ROYAL SERIES">CROWN ROYAL SERIES </option>
  <option value="CROWN SEDAN ">CROWN SEDAN </option>
  <option value="CROWN STATION WAGON">CROWN STATION WAGON </option>
  <option value="CROWN VAN">CROWN VAN </option>
  <option value="CRUISER">CRUISER </option>
  <option value="CRUZE">CRUZE </option>
  <option value="CT">CT </option>
  <option value="CTS">CTS </option>
  <option value="CUBE">CUBE </option>
  <option value="CUBE CUBIC ">CUBE CUBIC </option>
  <option value="CULTUS">CULTUS </option>
  <option value="CURREN">CURREN </option>
  <option value="CX-3">CX-3 </option>
  <option value="CX-30">CX-30 </option>
  <option value="CX-5">CX-5 </option>
  <option value="CX-60">CX-60 </option>
  <option value="CX-7 ">CX-7 </option>
  <option value="CX-8">CX-8 </option>
  <option value="CX-9">CX-9 </option>
  <option value="CYNOS">CYNOS </option>
  <option value="D-MAX">D-MAX </option>
  <option value="D5">D5 </option>
  <option value="DAEWOO OTHERS">DAEWOO OTHERS </option>
  <option value="DAIHATSU OTHERS">DAIHATSU OTHERS </option>
  <option value="DAIMLER OTHERS ">DAIMLER OTHERS </option>
  <option value="DAKOTA">DAKOTA </option>
  <option value="DAMAS">DAMAS </option>
  <option value="DASHING">DASHING </option>
  <option value="DATSUN PICKUP">DATSUN PICKUP </option>
  <option value="DATSUN TRUCK">DATSUN TRUCK </option>
  <option value="DATSUN VAN">DATSUN VAN </option>
  <option value="DAYZ">DAYZ </option>
  <option value="DAYZ ROOX">DAYZ ROOX </option>
  <option value="DB9">DB9 </option>
  <option value="DBS">DBS </option>
  <option value="DEBONAIR">DEBONAIR </option>
  <option value="DEBONAIR V">DEBONAIR V </option>
  <option value="DEFENDEDR 110">DEFENDEDR 110 </option>
  <option value="DEFENDER">DEFENDER </option>
  <option value="164">DELIBOY </option>
  <option value="DELICA">DELICA </option>
  <option value="DELICA CARGO">DELICA CARGO </option>
  <option value="DELICA D2">DELICA D2 </option>
  <option value="DELICA D3">DELICA D3 </option>
  <option value="DELICA D5">DELICA D5 </option>
  <option value="DELICA MINI">DELICA MINI </option>
  <option value="DELICA SPACEGEAR">DELICA SPACEGEAR </option>
  <option value="DELICA STARWAGON">DELICA STARWAGON </option>
  <option value="DELICA TRUCK ">DELICA TRUCK </option>
  <option value="DELICA VAN">DELICA VAN </option>
  <option value="DELTA">DELTA </option>
  <option value="DELTA TRUCK">DELTA TRUCK </option>
  <option value="DEMIO">DEMIO </option>
  <option value="DEVILLE">DEVILLE </option>
  <option value="DEX">DEX </option>
  <option value="DFM OTHERS">DFM OTHERS </option>
  <option value="DIAMANTE SEDAN">DIAMANTE SEDAN </option>
  <option value="DIAMANTE WAGON">DIAMANTE WAGON </option>
  <option value="DIAS WAGON ">DIAS WAGON </option>
  <option value="DIGNITY">DIGNITY </option>
  <option value="DINGO">DINGO </option>
  <option value="DISCOVERY">DISCOVERY </option>
  <option value="DISCOVERY SPORT">DISCOVERY SPORT </option>
  <option value="DOBLO">DOBLO </option>
  <option value="DODGE OTHERS">DODGE OTHERS </option>
  <option value="DOMANI">DOMANI </option>
  <option value="DOMINGO">DOMINGO </option>
  <option value="DRAGON TRUCK">DRAGON TRUCK </option>
  <option value="DREAMER">DREAMER </option>
  <option value="DS 3 CROSSBACK ">DS 3 CROSSBACK </option>
  <option value="DS 7 CROSSBACK">DS 7 CROSSBACK </option>
  <option value="DS AUTOMOBILES OTHERS">DS AUTOMOBILES OTHERS </option>
  <option value="DS3">DS3 </option>
  <option value="DS4">DS4 </option>
  <option value="DS5">DS5 </option>
  <option value="DTS">DTS </option>
  <option value="DUALIS">DUALIS </option>
  <option value="DUCATO CARGO">DUCATO CARGO </option>
  <option value="DUCATO VAN">DUCATO VAN </option>
  <option value="DUEGO">DUEGO </option>
  <option value="DUET">DUET </option>
  <option value="DUOLIKA D6(DUMP TRUCK)">DUOLIKA D6(DUMP TRUCK) </option>
  <option value="DUOLIKA D6(GARBAGE TRUCK)">DUOLIKA D6(GARBAGE TRUCK) </option>
  <option value="DURANGO">DURANGO </option>
  <option value="DUTRO">DUTRO </option>
  <option value="DYNA ROUTE VAN">DYNA ROUTE VAN </option>
  <option value="DYNA TRUCK">DYNA TRUCK </option>
  <option value="DYNA URBAN SUPPORTER ">DYNA URBAN SUPPORTER </option>
  <option value="DYNASTY">DYNASTY </option>
  <option value="DZIRE GLX">DZIRE GLX </option>
  <option value="E-TRON">E-TRON </option>
  <option value="E-CLASS ">E-CLASS </option>
  <option value="E-CLASS CONVERTIBLE ">E-CLASS CONVERTIBLE </option>
  <option value="E-NV200 VAN">E-NV200 VAN </option>
  <option value="E-PACE">E-PACE </option>
  <option value="E-TYPE">E-TYPE </option>
  <option value="E:NP">E:NP1 </option>
  <option value="E:NS1">E:NS1 </option>
  <option value="EBT-F17DG-1">EBT-F17DG-1 </option>
  <option value="EBT-F182">EBT-F182 </option>
  <option value="v">EBT-F34G-1 </option>
  <option value="EC180">EC180 </option>
  <option value="EC55C">EC55C </option>
  <option value="ECLIPSE">ECLIPSE </option>
  <option value="ECLIPSE CROSS">ECLIPSE CROSS </option>
  <option value="ECLIPSESPYDER">ECLIPSESPYDER </option>
  <option value="ECONIC">ECONIC </option>
  <option value="ECONOLINE">ECONOLINE </option>
  <option value="ECOSPORTS">ECOSPORTS </option>
  <option value="EDGE">EDGE </option>
  <option value="EDIX">EDIX </option>
  <option value="EK ACTIVE">EK ACTIVE </option>
  <option value="EK CLASSY">EK CLASSY </option>
  <option value="EK CUSTOM">EK CUSTOM </option>
  <option value="EK SPACE">EK SPACE </option>
  <option value="EK SPORT">EK SPORT </option>
  <option value="EK WAGON">EK WAGON </option>
  <option value="EK X">EK X </option>
  <option value="EK X EV">EK X EV </option>
  <option value="EK X SPACE">EK X SPACE </option>
  <option value="ELANTRA">ELANTRA </option>
  <option value="ELDORADO">ELDORADO </option>
  <option value="ELEMENT">ELEMENT </option>
  <option value="ELF TRUCK">ELF TRUCK </option>
  <option value="ELF UT ">ELF UT </option>
  <option value="ELF VAN ">ELF VAN </option>
  <option value="ELGRAND">ELGRAND </option>
  <option value="ELISE">ELISE </option>
  <option value="ELYSION">ELYSION </option>
  <option value="ENCLAVE">ENCLAVE </option>
  <option value="ENFINI MS-9">ENFINI MS-9 </option>
  <option value="ENFINI RX-7">ENFINI RX-7 </option>
  <option value="EOS">EOS </option>
  <option value="EQ900">EQ900 </option>
  <option value="EQA">EQA </option>
  <option value="EQC">EQC </option>
  <option value="EQE">EQE </option>
  <option value="EQS">EQS </option>
  <option value="EQUINOX">EQUINOX </option>
  <option value="EQUUS">EQUUS </option>
  <option value="ES">ES </option>
  <option value="ESCALADE">ESCALADE </option>
  <option value="ESCAPE">ESCAPE </option>
  <option value="ESCARGO">ESCARGO </option>
  <option value="ESCORT">ESCORT </option>
  <option value="ESCUDO">ESCUDO </option>
  <option value="ESPRIT">ESPRIT </option>
  <option value="ESQUIRE">ESQUIRE </option>
  <option value="ESSE">ESSE </option>
  <option value="ESTIMA">ESTIMA </option>
  <option value="ESTIMA EMINA">ESTIMA EMINA </option>
  <option value="ESTIMA HYBRID">ESTIMA HYBRID </option>
  <option value="ESTIMA L">ESTIMA L </option>
  <option value="ESTIMA LUCIDA">ESTIMA LUCIDA </option>
  <option value="ESTIMA T">ESTIMA T </option>
  <option value="ETERNA">ETERNA </option>
  <option value="EUNOS COSMO">EUNOS COSMO </option>
  <option value="EUNOS ROADSTER">EUNOS ROADSTER </option>
  <option value="EUROCARGO">EUROCARGO </option>
  <option value="EVEREST">EVEREST </option>
  <option value="EVERY">EVERY </option>
  <option value="EVERY LANDY">EVERY LANDY </option>
  <option value="EVERY PLUS ">EVERY PLUS </option>
  <option value="EVERY WAGON">EVERY WAGON </option>
  <option value="EVORA">EVORA </option>
  <option value="EX35">EX35 </option>
  <option value="EXA">EXA </option>
  <option value="EXCURSION">EXCURSION </option>
  <option value="EXIGA">EXIGA </option>
  <option value="EXIGA CROSSOVER 7">EXIGA CROSSOVER 7 </option>
  <option value="EXIGE">EXIGE </option>
  <option value="EXPEDITION">EXPEDITION </option>
  <option value="EXPERT">EXPERT </option>
  <option value="EXPLORER">EXPLORER </option>
  <option value="EXPLORER SPORT TRAC">EXPLORER SPORT TRAC </option>
  <option value="EXPRESS">EXPRESS </option>
  <option value="F TYPE">F TYPE </option>
  <option value="F-PACE">F-PACE </option>
  <option value="F150">F150 </option>
  <option value="F250">F250 </option>
  <option value="F350">F350 </option>
  <option value="F355">F355 </option>
  <option value="F430">F430 </option>
  <option value="FABIA">FABIA </option>
  <option value="FAIRLADY">FAIRLADY </option>
  <option value="FAIRLADY Z">FAIRLADY Z </option>
  <option value="FAMILIA">FAMILIA </option>
  <option value="FAMILIA S-WAGON">FAMILIA S-WAGON </option>
  <option value="FAMILIA VAN ">FAMILIA VAN </option>
  <option value="FARGO WAGON">FARGO WAGON </option>
  <option value="FAW OTHERS">FAW OTHERS </option>
  <option value="FB10-8">FB10-8 </option>
  <option value="FB10PN-77-300">FB10PN-77-300 </option>
  <option value="FB15-8">FB15-8 </option>
  <option value="FB15PN-75-300">FB15PN-75-300 </option>
  <option value="FB15PN-75B-350">FB15PN-75B-350 </option>
  <option value="FB15PN-77B-300 ">FB15PN-77B-300 </option>
  <option value="FBR14-80BC-300">FBR14-80BC-300 </option>
  <option value="FBR15-80-300">FBR15-80-300 </option>
  <option value="FBRM12-80B-300">FBRM12-80B-300 </option>
  <option value=">FBRM12N-80-300">FBRM12N-80-300 </option>
  <option value="FBRM12N-80-400">FBRM12N-80-400 </option>
  <option value="FBRM13-H75B-400">FBRM13-H75B-400 </option>
  <option value="FBRM14-80B-400 ">FBRM14-80B-400 </option>
  <option value="FBRM15-75-300 ">FBRM15-75-300 </option>
  <option value="FBRM15-80-300 ">FBRM15-80-300 </option>
  <option value="FBRM15-80-400SF">FBRM15-80-400SF </option>
  <option value="FBRM15-80B-300">FBRM15-80B-300 </option>
  <option value="FBRM15-80B-400">FBRM15-80B-400 </option>
  <option value="FBRM9-75-300">FBRM9-75-300 </option>
  <option value="FBRMA14-80B-450">FBRMA14-80B-450 </option>
  <option value="FBRW14-80BC-450M ">FBRW14-80BC-450M </option>
  <option value="FBRW14-H80B-370MCS">FBRW14-H80B-370MCS </option>
  <option value="FBT15PN-80B-400">FBT15PN-80B-400 </option>
  <option value="FBT20PN-67-300">FBT20PN-67-300 </option>
  <option value="FD20C-16">FD20C-16 </option>
  <option value="FD20C-17">FD20C-17 </option>
  <option value="FD25C-15">FD25C-15 </option>
  <option value="FD25T4">FD25T4 </option>
  <option value="FD28T-12">FD28T-12 </option>
  <option value="FD30T-17 ">FD30T-17 </option>
  <option value="FD35AT">FD35AT </option>
  <option value="FD35AT-17 ">FD35AT-17 </option>
  <option value="FE">FE </option>
  <option value="FERRARI OTHERS ">FERRARI OTHERS </option>
  <option value="FESTIVA MINI WAGON">FESTIVA MINI WAGON </option>
  <option value="FG15C-18">FG15C-18 </option>
  <option value="FG20C-16">FG20C-16 </option>
  <option value="FG25C-16">FG25C-16 </option>
  <option value="FGE15T">FGE15T </option>
  <option value="FGE15T5">FGE15T5 </option>
  <option value="FH">FH </option>
  <option value="FH12">FH12 </option>
  <option value="FH13">FH13 </option>
  <option value="FIAT OTHERS">FIAT OTHERS </option>
  <option value="FIESTA">FIESTA </option>
  <option value="FIGARO">FIGARO </option>
  <option value="FIGHTER">FIGHTER </option>
  <option value="FIGHTER MIGNON">FIGHTER MIGNON </option>
  <option value="FIT">FIT </option>
  <option value="FIT ARIA">FIT ARIA </option>
  <option value="FIT HYBRID ">FIT HYBRID </option>
  <option value="FIT SHUTTLE">FIT SHUTTLE </option>
  <option value="FIT SHUTTLE HYBRID">FIT SHUTTLE HYBRID </option>
  <option value="FJ CRUISER">FJ CRUISER </option>
  <option value="FL">FL </option>
  <option value="FL 7">FL 7 </option>
  <option value="FLAIR">FLAIR </option>
  <option value="FLAIR CROSSOVER">FLAIR CROSSOVER </option>
  <option value="FLAIR WAGON">FLAIR WAGON </option>
  <option value="FLEETWOOD">FLEETWOOD </option>
  <option value="FM">FM  </option>
  <option value="FM 12">FM 12 </option>
  <option value="FM 13">FM 13 </option>
  <option value="FM 9">FM 9 </option>
  <option value="811">FOCUS </option>
  <option value="FORD OTHERS">FORD OTHERS </option>
  <option value="FORESTER">FORESTER </option>
  <option value="FORFOUR">FORFOUR </option>
  <option value="FORTE">FORTE </option>
  <option value="FORTUNER">FORTUNER </option>
  <option value="FORTWO">FORTWO </option>
  <option value="FORWARD">FORWARD </option>
  <option value="FORWARD JUSTON">FORWARD JUSTON </option>
  <option value="FR15B">FR15B </option>
  <option value="FRANCE OTHERS">FRANCE OTHERS </option>
  <option value="FRB15-8">FRB15-8 </option>
  <option value="FRB15-8A">FRB15-8A </option>
  <option value="FREDA">FREDA </option>
  <option value="FREE">FREE </option>
  <option value="FREED">FREED </option>
  <option value="FREED HYBRID ">FREED HYBRID </option>
  <option value="FREED SPIKE">FREED SPIKE </option>
  <option value="FREED+ HYBRID">FREED+ HYBRID </option>
  <option value="FREELANDER">FREELANDER </option>
  <option value="FREELANDER 2">FREELANDER 2 </option>
  <option value="FREEMONT">FREEMONT </option>
  <option value="FREESTAR">FREESTAR </option>
  <option value="FRONTE">FRONTE </option>
  <option value="FRONTIER">FRONTIER </option>
  <option value="FTO">FTO </option>
  <option value="FUGA">FUGA </option>
  <option value="FUGA HYBRID">FUGA HYBRID </option>
  <option value="FUN CARGO">FUN CARGO </option>
  <option value="FUSION">FUSION </option>
  <option value="FUSO OTHERS ">FUSO OTHERS </option>
  <option value="FX30">FX30 </option>
  <option value="FX50">FX50 </option>
  <option value="G SERIES">G SERIES </option>
  <option value="G-CLASS">G-CLASS </option>
  <option value="G35">G35 </option>
  <option value="G37">G37 </option>
  <option value="G6">G6 </option>
  <option value="G70">G70 </option>
  <option value="G80">G80 </option>
  <option value="G90">G90 </option>
  <option value="GAIA">GAIA </option>
  <option value="GALA">GALA </option>
  <option value="GALAMIO">GALAMIO </option>
  <option value="GALANT">GALANT </option>
  <option value="GALANT FORTIS">GALANT FORTIS </option>
  <option value="GALANT SPORTS">GALANT SPORTS </option>
  <option value="GALLARDO">GALLARDO </option>
  <option value="GALLOPER">GALLOPER </option>
  <option value="GALUE">GALUE </option>
  <option value="GALUE 204">GALUE 204 </option>
  <option value="GALUE-IT">GALUE-II </option>
  <option value="GAZELLE">GAZELLE </option>
  <option value="GEMINI">GEMINI </option>
  <option value="GENESIS">GENESIS </option>
  <option value="GHIBLI">GHIBLI </option>
  <option value="GHOST">GHOST </option>
  <option value="GIGA">GIGA </option>
  <option value="GIULIETTA">GIULIETTA </option>
  <option value="GL-CLASS">GL-CLASS </option>
  <option value="GLA-CLASS">GLA-CLASS </option>
  <option value="GLB-CLASS">GLB-CLASS </option>
  <option value="GLC-CLASS ">GLC-CLASS </option>
  <option value="GLE-CLASS">GLE-CLASS </option>
  <option value="GLK-CLAS">GLK-CLASS </option>
  <option value="GLORIA CIMA">GLORIA CIMA </option>
  <option value="GLORIA HARDTOP ">GLORIA HARDTOP </option>
  <option value="GLORIA VAN">GLORIA VAN </option>
  <option value="GLORIA WAGON">GLORIA WAGON </option>
  <option value="GLORIA(SEDAN)">GLORIA(SEDAN) </option>
  <option value="GLORY">GLORY </option>
  <option value="GLS CLASS">GLS CLASS </option>
  <option value="GMC OTHERS">GMC OTHERS </option>
  <option value="GOLF">GOLF </option>
  <option value="GOLF ALLTRACK">GOLF ALLTRACK </option>
  <option value="GOLF GTE">GOLF GTE </option>
  <option value="GOLF GTI">GOLF GTI </option>
  <option value="GOLF PLUS">GOLF PLUS </option>
  <option value="GOLF R">GOLF R </option>
  <option value="GOLF R VARIANT">GOLF R VARIANT </option>
  <option value="GOLF TOURAN ">GOLF TOURAN </option>
  <option value="GOLF VARIANT ">GOLF VARIANT </option>
  <option value="GOLF WAGON">GOLF WAGON </option>
  <option value="GRACE">GRACE </option>
  <option value="GRAN ACE">GRAN ACE </option>
  <option value="GRANBIRD">GRANBIRD </option>
  <option value="GRAND CARAVAN">GRAND CARAVAN </option>
  <option value="GRAND CARNIVAL">GRAND CARNIVAL </option>
  <option value="GRAND CHEROKEE">GRAND CHEROKEE </option>
  <option value="GRAND HIACE">GRAND HIACE </option>
  <option value="GRAND STAREX">GRAND STAREX </option>
  <option value="GRAND VITARA">GRAND VITARA </option>
  <option value="GRAND VOYAGER">GRAND VOYAGER </option>
  <option value="GRANDE PUNTO">GRANDE PUNTO </option>
  <option value="GRANDEUR">GRANDEUR </option>
  <option value="GRANDEUR GN7">GRANDEUR GN7 </option>
  <option value="GRANDEUR HG ">GRANDEUR HG </option>
  <option value="GRANDEUR IG ">GRANDEUR IG </option>
  <option value="GRANDEUR TG">GRANDEUR TG </option>
  <option value="GRANDIS">GRANDIS </option>
  <option value="GRANSPORT">GRANSPORT </option>
  <option value="GRANTURISMO">GRANTURISMO </option>
  <option value="GRANVIA">GRANVIA </option>
  <option value="GREAT">GREAT </option>
  <option value="GREAT WALL MOTOR OTHERS">GREAT WALL MOTOR OTHERS </option>
  <option value="GS">GS </option>
  <option value="GS HYBRID">GS HYBRID </option>
  <option value="GS3">GS3 </option>
  <option value="GS4">GS4 </option>
  <option value="GS8">GS8 </option>
  <option value="GT">GT </option>
  <option value="GTO">GTO </option>
  <option value="GTV">GTV </option>
  <option value="GV70">GV70 </option>
  <option value="GV80">GV80 </option>
  <option value="GX 460">GX 460 </option>
  <option value="H1">H1 </option>
  <option value="H100">H100 </option>
  <option value="H2">H2 </option>
  <option value="H3">H3 </option>
  <option value="H6">H6 </option>
  <option value="HAN">HAN </option>
  <option value="HARRIER">HARRIER </option>
  <option value="HARRIER HYBRID">HARRIER HYBRID </option>
  <option value="HD">HD </option>
  <option value="HHR">HHR </option>
  <option value="HIACE COMMUTER">HIACE COMMUTER </option>
  <option value="HIACE TRUCK">HIACE TRUCK </option>
  <option value="HIACE VAN">HIACE VAN </option>
  <option value="HIACE WAGON">HIACE WAGON </option>
  <option value="HIGHLANDER">HIGHLANDER </option>
  <option value="HIJET CADDIE">HIJET CADDIE </option>
  <option value="HIJET CARGO ">HIJET CARGO </option>
  <option value="HIJET GRANCARGO">HIJET GRANCARGO </option>
  <option value="HIJET TRUCK">HIJET TRUCK </option>
  <option value="HIJET VAN">HIJET VAN </option>
  <option value="HILUX">HILUX </option>
  <option value="HILUX SPORTS PICKUP">HILUX SPORTS PICKUP </option>
  <option value="HILUX SURF">HILUX SURF </option>
  <option value="HILUX TRUCK ">HILUX TRUCK </option>
  <option value="HINO OTHERS">HINO OTHERS </option>
  <option value="HITACHI OTHERS">HITACHI OTHERS </option>
  <option value="HOMY COACH ">HOMY COACH </option>
  <option value="HOMY ELGRAND">HOMY ELGRAND </option>
  <option value="HOMY VAN">HOMY VAN </option>
  <option value="HONDA OTHERS ">HONDA OTHERS </option>
  <option value="HONGGUANG S3">HONGGUANG S3 </option>
  <option value="HONGGUANG V ">HONGGUANG V </option>
  <option value="HR-V ">HR-V </option>
  <option value="HS">HS </option>
  <option value="HS EXCLUSIVE">HS EXCLUSIVE </option>
  <option value="HUSTLER">HUSTLER </option>
  <option value="HYSTER OTHERS">HYSTER OTHERS </option>
  <option value="HYUNDAI OTHERS">HYUNDAI OTHERS </option>
  <option value="I">I </option>
  <option value="I-MIEV">I-MIEV </option>
  <option value="I3">I3 </option>
  <option value="I30">I30 </option>
  <option value="I30CW">I30CW </option>
  <option value="I40">I40 </option>
  <option value="I45">I45 </option>
  <option value="IBIZA">IBIZA </option>
  <option value="ID.4">ID.4 </option>
  <option value="ID.6">ID.6 </option>
  <option value="IGNIS">IGNIS </option>
  <option value="IMPALA">IMPALA </option>
  <option value="IMPREZA">IMPREZA </option>
  <option value="IMPREZA ANESIS">IMPREZA ANESIS </option>
  <option value="IMPREZA G4 ">IMPREZA G4 </option>
  <option value="IMPREZA HARDTOP SEDAN">IMPREZA HARDTOP SEDAN </option>
  <option value="IMPREZA RETNA">IMPREZA RETNA </option>
  <option value="IMPREZA SPORT HYBRID">IMPREZA SPORT HYBRID </option>
  <option value="IMPREZA SPORTS">IMPREZA SPORTS </option>
  <option value="IMPREZA SPORTSWAGON">IMPREZA SPORTSWAGON </option>
  <option value="IMPREZA WRX">IMPREZA WRX </option>
  <option value="IMPREZA WRX STI">IMPREZA WRX STI </option>
  <option value="IMPREZA XV">IMPREZA XV </option>
  <option value="IMPREZA XV HYBRID">IMPREZA XV HYBRID </option>
  <option value="INFINITI OTHERS">INFINITI OTHERS </option>
  <option value="INSIGHT">INSIGHT </option>
  <option value="INSIGHT EXCLUSIVE">INSIGHT EXCLUSIVE </option>
  <option value="INSPIRE">INSPIRE </option>
  <option value="INTEGRA">INTEGRA </option>
  <option value="IONIQ">IONIQ </option>
  <option value="IPSUM">IPSUM </option>
  <option value="IQ">IQ </option>
  <option value="IS">IS </option>
  <option value="IS F">IS F </option>
  <option value="ISIS">ISIS </option>
  <option value="IST">IST </option>
  <option value="ISUZU OTHERS">ISUZU OTHERS </option>
  <option value="IVECO OTHERS">IVECO OTHERS </option>
  <option value="IX25">IX25 </option>
  <option value="IX35">IX35 </option>
  <option value="IZOA">IZOA </option>
  <option value="J1B1">J1B1 </option>
  <option value="J1B1">J1B1 </option>
  <option value="JADE">JADE </option>
  <option value="JAGUAR OTHERS">JAGUAR OTHERS </option>
  <option value="JAZZ">JAZZ </option>
  <option value="JC">JC </option>
  <option value="JCB OTHERS">JCB OTHERS </option>
  <option value="JEEP">JEEP </option>
  <option value="JEEP OTHERS">JEEP OTHERS </option>
  <option value="JETTA">JETTA </option>
  <option value="JIMNY">JIMNY </option>
  <option value="JIMNY SIERRA">JIMNY SIERRA </option>
  <option value="JIMNY WIDE">JIMNY WIDE </option>
  <option value="JOHN DEER OTHERS">JOHN DEER OTHERS </option>
  <option value="JOURNEY">JOURNEY </option>
  <option value="JOURNEY BUS">JOURNEY BUS </option>
  <option value="JUKE">JUKE </option>
  <option value="JUSTON">JUSTON </option>
  <option value="JUSTY">JUSTY </option>
  <option value="K-1500">K-1500 </option>
  <option value="K1B1">K1B1 </option>
  <option value="K3">K3 </option>
  <option value="K5 (OPTIMA)">K5 (OPTIMA) </option>
  <option value="K5 3RD GEN">K5 3RD GEN </option>
  <option value="K5 MX">K5 MX </option>
  <option value="K7">K7 </option>
  <option value="K7 PREMIER ">K7 PREMIER </option>
  <option value="K8">K8 </option>
  <option value="K9">K9 </option>
  <option value="KA">KA </option>
  <option value="KANGOO">KANGOO </option>
  <option value="KARMANN GHIA">KARMANN GHIA </option>
  <option value="KAZET">KAZET </option>
  <option value="KEI">KEI </option>
  <option value="KENBO-600">KENBO-600 </option>
  <option value="KERAX">KERAX </option>
  <option value="KIA MOTORS OTHERS">KIA MOTORS OTHERS </option>
  <option value="KICKS">KICKS </option>
  <option value="KIX">KIX </option>
  <option value="KIZASHI">KIZASHI </option>
  <option value="KLUGER">KLUGER </option>
  <option value="KLUGER HYBRID">KLUGER HYBRID </option>
  <option value="KOBELCO OTHERS">KOBELCO OTHERS </option>
  <option value="KOLEOS">KOLEOS </option>
  <option value="KOMATSU OTHERS">KOMATSU OTHERS </option>
  <option value="KONA">KONA </option>
  <option value="KORANDO">KORANDO </option>
  <option value="KORANDO C">KORANDO C </option>
  <option value="KORANDO SPORTS">KORANDO SPORTS </option>
  <option value="KORANDO TURISMO">KORANDO TURISMO </option>
  <option value="KOREA OTHERS">KOREA OTHERS </option>
  <option value="KUBOTA OTHERS ">KUBOTA OTHERS </option>
  <option value="KUGA">KUGA </option>
  <option value="KYRON">KYRON </option>
  <option value="L200">L200 </option>
  <option value="L7">L7 </option>
  <option value="L8">L8 </option>
  <option value="L9">L9 </option>
  <option value="LABO">LABO </option>
  <option value="LACETTI">LACETTI </option>
  <option value="LACETTI (CRUZE)">LACETTI (CRUZE) </option>
  <option value="LACROSSE">LACROSSE </option>
  <option value="LAFESTA">LAFESTA </option>
  <option value="LAGREAT">LAGREAT </option>
  <option value="LAMBORGHINI OTHERS">LAMBORGHINI OTHERS </option>
  <option value="LANCER">LANCER </option>
  <option value="LANCER CARGO">LANCER CARGO </option>
  <option value="LANCER CEDIA">LANCER CEDIA </option>
  <option value="LANCER CEDIA WAGON">LANCER CEDIA WAGON </option>
  <option value="LANCER EVOLUTION">LANCER EVOLUTION </option>
  <option value="LANCER EVOLUTION VIII">LANCER EVOLUTION VIII </option>
  <option value="LANCER EVOLUTION WAGON">LANCER EVOLUTION WAGON </option>
  <option value="LANCER VAN">LANCER VAN </option>
  <option value="LANCER WAGON">LANCER WAGON </option>
  <option value="LAND CRUISER">LAND CRUISER </option>
  <option value="LAND CRUISER AMAZON ">LAND CRUISER AMAZON </option>
  <option value="LAND CRUISER CYGNUS">LAND CRUISER CYGNUS </option>
  <option value="LAND CRUISER PRADO">LAND CRUISER PRADO </option>
  <option value="LAND ROVER OTHERS ">LAND ROVER OTHERS </option>
  <option value="LANDY">LANDY </option>
  <option value="LANTIS">LANTIS </option>
  <option value="LAPIN">LAPIN (2,919)</option>
  <option value="LAPUTA">LAPUTA </option>
  <option value="LARGO">LARGO </option>
  <option value="LASETTI">LASETTI </option>
  <option value="LATIO">LATIO </option>
  <option value="LAUREL">LAUREL </option>
  <option value="LAVIDA">LAVIDA </option>
  <option value="LE SEYDE">LE SEYDE </option>
  <option value="LEAF">LEAF </option>
  <option value="LEGACY">LEGACY </option>
  <option value="LEGACY B4">LEGACY B4 </option>
  <option value="LEGACY LANCASTER">LEGACY LANCASTER </option>
  <option value="LEGACY TOURING WAGON">LEGACY TOURING WAGON </option>
  <option value="LEGEND">LEGEND </option>
  <option value="LEGNUM">LEGNUM </option>
  <option value="LEON">LEON </option>
  <option value="LEONE">LEONE </option>
  <option value="LEOPARD">LEOPARD </option>
  <option value="LEOPARD JFEREE">LEOPARD JFEREE </option>
  <option value="LEVANTE">LEVANTE </option>
  <option value="LEVORG">LEVORG </option>
  <option value="LEXUS OTHERS">LEXUS OTHERS </option>
  <option value="LF">LF </option>
  <option value="LF SONATA">LF SONATA </option>
  <option value="LF45">LF45 </option>
  <option value="LF55">LF55 </option>
  <option value="LIBERTY">LIBERTY </option>
  <option value="LIESSE">LIESSE </option>
  <option value="LIESSE II">LIESSE II </option>
  <option value="LIFE">LIFE </option>
  <option value="LIFE DUNK">LIFE DUNK </option>
  <option value="LINCOLN OTHERS">LINCOLN OTHERS </option>
  <option value="LITEACE NOAH">LITEACE NOAH </option>
  <option value="LITEACE TRUCK">LITEACE TRUCK </option>
  <option value="LITEACE VAN">LITEACE VAN </option>
  <option value="LITEACE WAGON">LITEACE WAGON </option>
  <option value="LIVINA">LIVINA </option>
  <option value="LOBO">LOBO </option>
  <option value="LOGO">LOGO </option>
  <option value="LORENZ LANCLE OTHERS">LORENZ&amp;LANCLE OTHERS </option>
  <option value="LOTUS OTHERS">LOTUS OTHERS </option>
  <option value="LOTZE">LOTZE </option>
  <option value="LS">LS </option>
  <option value="LS7">LS7 </option>
  <option value="LUCE">LUCE </option>
  <option value="LUCINO">LUCINO </option>
  <option value="LUCRA">LUCRA </option>
  <option value="LUCRA CUSTOM ">LUCRA CUSTOM </option>
  <option value="LUPO">LUPO </option>
  <option value="LUTECIA">LUTECIA </option>
  <option value="LX">LX </option>
  <option value="M MODEL">M MODEL </option>
  <option value="M-CLASS">M-CLASS </option>
  <option value="M2">M2 </option>
  <option value="M3">M3 </option>
  <option value="M37">M37 </option>
  <option value="M4">M4 </option>
  <option value="M5">M5 </option>
  <option value="M6">M6 </option>
  <option value="M8">M8 </option>
  <option value="MACAN">MACAN </option>
  <option value="MAGNUM">MAGNUM </option>
  <option value="MAGOTAN">MAGOTAN </option>
  <option value="MALIBU">MALIBU </option>
  <option value="MAN OTHERS">MAN OTHERS </option>
  <option value="MANJARO">MANJARO </option>
  <option value="MARCH">MARCH </option>
  <option value="MARCH BOX">MARCH BOX </option>
  <option value="MARK II">MARK II </option>
  <option value="MARK II BLIT">MARK II BLIT </option>
  <option value="MARK II QUALIS">MARK II QUALIS </option>
  <option value="MARK II VAN">MARK II VAN </option>
  <option value="MARK II WAGON">MARK II WAGON </option>
  <option value="MARK VIII">MARK VIII </option>
  <option value="MARK X">MARK X</option>
  <option value="MARK X ZIO">MARK X ZIO </option>
  <option value="MASERATI OTHERS">MASERATI OTHERS </option>
  <option value="MASSEY FERGUSON OTHERS">MASSEY FERGUSON OTHERS </option>
  <option value="MASTER">MASTER </option>
  <option value="MASTER MINIBUS">MASTER MINIBUS </option>
  <option value="MASTERACE SURF ">MASTERACE SURF </option>
  <option value="MATIZ">MATIZ </option>
  <option value="MATIZ (SPARK)">MATIZ (SPARK) </option>
  <option value="MAX">MAX </option>
  <option value="MAXCRUZ">MAXCRUZ </option>
  <option value="MAXIMA">MAXIMA </option>
  <option value="MAYBACH OTHERS">MAYBACH OTHERS </option>
  <option value="MAZDA OTHERS">MAZDA OTHERS </option>
  <option value="MAZDA2">MAZDA2 </option>
  <option value="MAZDA3">MAZDA3 </option>
  <option value="MAZDA5">MAZDA5 </option>
  <option value="MAZDA6">MAZDA6 </option>
  <option value="MAZDA8">MAZDA8 </option>
  <option value="MCC OTHERS">MCC OTHERS </option>
  <option value="MCLAREN OTHERS ">MCLAREN OTHERS </option>
  <option value="MDX">MDX </option>
  <option value="MEBIUS">MEBIUS </option>
  <option value="MEGACRUISER">MEGACRUISER </option>
  <option value="MEGANE">MEGANE </option>
  <option value="MEGANE ESTATE">MEGANE ESTATE </option>
  <option value="MEGANE TOURING WAGON">MEGANE TOURING WAGON </option>
  <option value="MEGATRUCK">MEGATRUCK </option>
  <option value="MELPHA">MELPHA </option>
  <option value="MERCEDES-BENZ OTHERS">MERCEDES-BENZ OTHERS </option>
  <option value="MERIVA">MERIVA </option>
  <option value="MG 5">MG 5 </option>
  <option value="v 6">MG 6 </option>
  <option value="MG EXTENDER">MG EXTENDER </option>
  <option value="MG OTHERS">MG OTHERS </option>
  <option value="MGF">MGF </option>
  <option value="MIATA MX-5">MIATA MX-5 </option>
  <option value="MICRA">MICRA </option>
  <option value="MICRAC C+C">MICRAC C+C </option>
  <option value="MIDGET II">MIDGET II </option>
  <option value="MIDIUM CLASS">MIDIUM CLASS </option>
  <option value="MIDLUM">MIDLUM </option>
  <option value="MIGHTY">MIGHTY </option>
  <option value="MIGHTY BOY">MIGHTY BOY </option>
  <option value="MILLENIA">MILLENIA </option>
  <option value="MINI">MINI </option>
  <option value="MINI CLUBMAN">MINI CLUBMAN </option>
  <option value="MINI EV">MINI EV </option>
  <option value="MINI EV GAMEBOY">MINI EV GAMEBOY </option>
  <option value="MINI EV MACARON">MINI EV MACARON </option>
  <option value="MINI OTHERS">MINI OTHERS </option>
  <option value="MINICA">MINICA </option>
  <option value="MINICA TOPO">MINICA TOPO </option>
  <option value="MINICAB MIEV">MINICAB MIEV </option>
  <option value="MINICAB TRUCK">MINICAB TRUCK </option>
  <option value="MINICAB VAN">MINICAB VAN </option>
  <option value="MIRA">MIRA </option>
  <option value="MIRA AVY">MIRA AVY </option>
  <option value="MIRA COCOA">MIRA COCOA </option>
  <option value="MIRA CUSTOM">MIRA CUSTOM </option>
  <option value="MIRA ES">MIRA ES </option>
  <option value="MIRA GINO1000 ">MIRA GINO1000 </option>
  <option value="MIRAGE">MIRAGE </option>
  <option value="MIRAGE HATCHBACK">MIRAGE HATCHBACK </option>
  <option value="MIRAGINO">MIRAGINO </option>
  <option value="MISTRA">MISTRA </option>
  <option value="MISTRAL">MISTRAL </option>
  <option value="MITO">MITO </option>
  <option value="MITSUBISHI OTHERS">MITSUBISHI OTHERS </option>
  <option value="MITSUOKA OTHERS">MITSUOKA OTHERS </option>
  <option value="MKS">MKS </option>
  <option value="MKX">MKX </option>
  <option value="MKZ">MKZ </option>
  <option value="ML CLASS">ML CLASS </option>
  <option value="MOBILIO">MOBILIO </option>
  <option value="MOBILIO SPIKE">MOBILIO SPIKE </option>
  <option value="MOCO">MOCO </option>
  <option value="MODEL 3">MODEL 3 </option>
  <option value="MODEL S">MODEL S </option>
  <option value="MODEL X">MODEL X </option>
  <option value="MODEL Y">MODEL Y </option>
  <option value="MOHAVE">MOHAVE </option>
  <option value="MOHAVE THE MASTER">MOHAVE THE MASTER </option>
  <option value="MONDEO">MONDEO </option>
  <option value="MONDIAL">MONDIAL </option>
  <option value="MONTERO">MONTERO </option>
  <option value="MONZA">MONZA </option>
  <option value="MONZA CHEVY">MONZA CHEVY </option>
  <option value="MORNING (PICANTO)">MORNING (PICANTO) </option>
  <option value="MORNING URBAN">MORNING URBAN </option>
  <option value="MOVE">MOVE </option>
  <option value="MOVE CANBUS ">MOVE CANBUS </option>
  <option value="MOVE CONTE">MOVE CONTE </option>
  <option value="MOVE CONTE CUSTOM ">MOVE CONTE CUSTOM </option>
  <option value="MOVE CUSTOM">MOVE CUSTOM </option>
  <option value="MOVE LATTE">MOVE LATTE </option>
  <option value="MPV">MPV </option>
  <option value="MR WAGON">MR WAGON </option>
  <option value="MR-S ">MR-S </option>
  <option value="MR2">MR2 </option>
  <option value="MU">MU </option>
  <option value="MU WIZARD">MU WIZARD </option>
  <option value="MULAN">MULAN </option>
  <option value="MULSANNE">MULSANNE </option>
  <option value="MULTIPLA">MULTIPLA </option>
  <option value="MURANO">MURANO</option>
  <option value="MURCIELAGO">MURCIELAGO </option>
  <option value="MUSTANG">MUSTANG </option>
  <option value="MW">MW </option>
  <option value="MX-30">MX-30 </option>
  <option value="MX-5">MX-5 </option>
  <option value="N BOX">N BOX </option>
  <option value="N BOX CUSTOM">N BOX CUSTOM </option>
  <option value="N BOX PLUS">N BOX PLUS </option>
  <option value="N BOX SLASH ">N BOX SLASH </option>
  <option value="N-ONE">N-ONE </option>
  <option value="N-VAN">N-VAN </option>
  <option value="N-WGN">N-WGN </option>
  <option value="N360">N360 </option>
  <option value="NADIA">NADIA </option>
  <option value="NAKED">NAKED </option>
  <option value="NAVARA">NAVARA </option>
  <option value="NAVIGATOR">NAVIGATOR </option>
  <option value="NEON">NEON </option>
  <option value="NETA V">NETA V </option>
  <option value="NEW BEETLE">NEW BEETLE </option>
  <option value="NEW CARNIVAL">NEW CARNIVAL </option>
  <option value="NEW HOLLAND OTHERS">NEW HOLLAND OTHERS </option>
  <option value="NEW MORNING">NEW MORNING </option>
  <option value="NEW PANDA">NEW PANDA </option>
  <option value="NEW SORENTO ">NEW SORENTO </option>
  <option value="NEW SORENTO R">NEW SORENTO R </option>
  <option value="NEW SPORTAGE ">NEW SPORTAGE </option>
  <option value="NEW TUCSON">NEW TUCSON </option>
  <option value="NEW TUCSON IX">NEW TUCSON IX </option>
  <option value="NF SONATA">NF SONATA </option>
  <option value="NIRO">NIRO </option>
  <option value="NISSAN GT-R ">NISSAN GT-R </option>
  <option value="NISSAN OTHERS ">NISSAN OTHERS </option>
  <option value="NITRO">NITRO </option>
  <option value="NJ01">NJ01 </option>
  <option value="NL01">NL01 </option>
  <option value="NOAH">NOAH</option>
  <option value="NOAH HYBRID">NOAH HYBRID </option>
  <option value="NOTE">NOTE </option>
  <option value="NOUERA">NOUERA </option>
  <option value="NOVUS">NOVUS </option>
  <option value="NSX">NSX </option>
  <option value="NT450 ATLAS">NT450 ATLAS </option>
  <option value="NV150 AD">NV150 AD </option>
  <option value="NV200VANETTE WAGON ">NV200VANETTE WAGON </option>
  <option value="NX">NX </option>
  <option value="OCTAVIA">OCTAVIA </option>
  <option value="ODYSSEY">ODYSSEY</option>
  <option value="OPA">OPA </option>
  <option value="OPIRUS">OPIRUS </option>
  <option value="OPTI">OPTI </option>
  <option value="ORIGIN">ORIGIN </option>
  <option value="ORLANDO">ORLANDO</option>
  <option value="OROCHI">OROCHI </option>
  <option value="ORTHIA">ORTHIA </option>
  <option value="OTHER">OTHER </option>
  <option value="OTTI">OTTI</option>
  <option value="OUTBACK">OUTBACK </option>
  <option value="OUTLANDER">OUTLANDER </option>
  <option value="OUTLANDER PHEV ">OUTLANDER PHEV </option>
  <option value="P SERIES">P SERIES </option>
  <option value="PA NERO ">PA NERO </option>
  <option value="PACEMAN">PACEMAN </option>
  <option value="PACIFICA">PACIFICA </option>
  <option value="PAJERO">PAJERO</option>
  <option value="PAJERO IO ">PAJERO IO </option>
  <option value="PAJERO JR">PAJERO JR </option>
  <option value="PAJERO MINI">PAJERO MINI </option>
  <option value="PAJERO VAN ">PAJERO VAN </option>
  <option value="PALETTE">PALETTE </option>
  <option value="PALETTESW">PALETTESW </option>
  <option value="PALIO">PALIO </option>
  <option value="PALISADE">PALISADE </option>
  <option value="PANAMERA">PANAMERA </option>
  <option value="PANDA">PANDA </option>
  <option value="PAO">PAO </option>
  <option value="PARTNER">PARTNER </option>
  <option value="PASSAT">PASSAT </option>
  <option value="PASSAT ALLTRACK">PASSAT ALLTRACK </option>
  <option value="PASSAT CC">PASSAT CC </option>
  <option value="PASSAT VARIANT ">PASSAT VARIANT </option>
  <option value="PASSAT WAGON">PASSAT WAGON </option>
  <option value="PASSO">PASSO </option>
  <option value="PASSO SETTE">PASSO SETTE </option>
  <option value="PATHFINDER">PATHFINDER </option>
  <option value="PATRIOT">PATRIOT </option>
  <option value="PATROL">PATROL </option>
  <option value="PATROL GR">PATROL GR </option>
  <option value="PEUGEOT 307">PEUGEOT 307 </option>
  <option value="PEUGOET OTHERS">PEUGOET OTHERS </option>
  <option value="PHAETON">PHAETON </option>
  <option value="PIAZZA">PIAZZA </option>
  <option value="PILOT">PILOT</option>
  <option value="PINO">PINO </option>
  <option value="PIXIS EPOCH">PIXIS EPOCH </option>
  <option value="PIXIS JOY">PIXIS JOY </option>
  <option value="PIXIS SPACE">PIXIS SPACE </option>
  <option value="PIXIS TRUCK ">PIXIS TRUCK </option>
  <option value="PIXIS VAN ">PIXIS VAN </option>
  <option value="PLATZ">PLATZ </option>
  <option value="PLEO">PLEO </option>
  <option value="PLEO CUSTOM">PLEO CUSTOM </option>
  <option value="PLEO NESTA">PLEO NESTA </option>
  <option value="PLEO PLUS ">PLEO PLUS </option>
  <option value="POLESTAR 2">POLESTAR 2 </option>
  <option value="POLO">POLO </option>
  <option value="PONCHO">PONCHO </option>
  <option value="PORSCHE OTHERS">PORSCHE OTHERS </option>
  <option value="PORTE">PORTE </option>
  <option value="PORTER">PORTER </option>
  <option value="PORTER CAB">PORTER CAB </option>
  <option value="PRADO">PRADO </option>
  <option value="PRAIRIEJOY">PRAIRIEJOY </option>
  <option value="PRELUDE">PRELUDE </option>
  <option value="PREMACY">PREMACY </option>
  <option value="PREMIO">PREMIO </option>
  <option value="PREMIUM">PREMIUM </option>
  <option value="PRESAGE">PRESAGE </option>
  <option value="PRESEA">PRESEA </option>
  <option value="PRESIDENT">PRESIDENT </option>
  <option value="PRESIDENT JS">PRESIDENT JS </option>
  <option value="PREVIA">PREVIA </option>
  <option value="PRIDE (RIO)">PRIDE (RIO) </option>
  <option value="PRIMERA">PRIMERA </option>
  <option value="PRIMERA CAMINO">PRIMERA CAMINO </option>
  <option value="PRIMERA CAMINO WAGON">PRIMERA CAMINO WAGON </option>
  <option value="PRIMERA WAGON">PRIMERA WAGON </option>
  <option value="PRIUS">PRIUS </option>
  <option value="PRIUS ALPHA ">PRIUS ALPHA </option>
  <option value="PRIUS C">PRIUS C </option>
  <option value="PRIUS PHV">PRIUS PHV </option>
  <option value="PROBE">PROBE </option>
  <option value="PROBOX">PROBOX</option>
  <option value="PROBOX VAN">PROBOX VAN </option>
  <option value="PROBOX WAGON">PROBOX WAGON </option>
  <option value="PROCEED">PROCEED </option>
  <option value="PROCEED LEVANTE">PROCEED LEVANTE </option>
  <option value="PROCEED MARVIE">PROCEED MARVIE </option>
  <option value="PROFIA">PROFIA </option>
  <option value="PROGRES">PROGRES </option>
  <option value="PRONARD">PRONARD </option>
  <option value="PROUDIA">PROUDIA </option>
  <option value="PT CRUISER">PT CRUISER </option>
  <option value="PT CRUISER CABRIO">PT CRUISER CABRIO </option>
  <option value="PUBLICA">PUBLICA </option>
  <option value="PULSAR">PULSAR </option>
  <option value="PULSAR SERIE">PULSAR SERIE </option>
  <option value="PULSAR SERIE S-RV ">PULSAR SERIE S-RV </option>
  <option value="PUNTO">PUNTO </option>
  <option value="PUNTO EVO">PUNTO EVO </option>
  <option value="PYZAR">PYZAR </option>
  <option value="Q2">Q2 </option>
  <option value="Q3">Q3</option>
  <option value="Q30">Q30 </option>
  <option value="Q4 E-TRON">Q4 E-TRON </option>
  <option value="Q45">Q45 </option>
  <option value="Q5">Q5 </option>
  <option value="Q50">Q50 </option>
  <option value="Q7">Q7 </option>
  <option value="Q70">Q70 </option>
  <option value="Q8">Q8 </option>
  <option value="QASHQAI">QASHQAI </option>
  <option value="QIN">QIN </option>
  <option value="QM3">QM3 </option>
  <option value="QM5">QM5 </option>
  <option value="QM6">QM6</option>
  <option value="QUATTROPORTE">QUATTROPORTE </option>
  <option value="QUICK DELIVERY">QUICK DELIVERY </option>
  <option value="QUON">QUON </option>
  <option value="QX50">QX50 </option>
  <option value="QX56">QX56 </option>
  <option value="QX70">QX70 </option>
  <option value="R SERIES">R SERIES </option>
  <option value="R-CLASS">R-CLASS </option>
  <option value="R1">R1 </option>
  <option value="R2">R2 </option>
  <option value="R8">R8 </option>
  <option value="RACTIS">RACTIS </option>
  <option value="RAINBOW">RAINBOW </option>
  <option value="RAIZE">RAIZE </option>
  <option value="RAM">RAM </option>
  <option value="RANGE ROVER ">RANGE ROVER </option>
  <option value="RANGE ROVER">RANGE ROVER </option>
  <option value="RANGE ROVER EVOQUE">RANGE ROVER EVOQUE </option>
  <option value="RANGE ROVER SPORT">RANGE ROVER SPORT</option>
  <option value="RANGE ROVER VELAR">RANGE ROVER VELAR</option>
  <option value="RANGE ROVER VOGUE">RANGE ROVER VOGUE </option>
  <option value="RANGER">RANGER </option>
  <option value="RANGER PRO">RANGER PRO </option>
  <option value="RANGER2">RANGER2 </option>
  <option value="RASHEEN">RASHEEN </option>
  <option value="RAUM">RAUM </option>
  <option value="RAV4">RAV4</option>
  <option value="RAV4 PHV">RAV4 PHV </option>
  <option value="RAY">RAY </option>
  <option value="RC">RC </option>
  <option value="RC F">RC F </option>
  <option value="RCZ">RCZ </option>
  <option value="RD6">RD6 </option>
  <option value="REFINE">REFINE </option>
  <option value="REGAL">REGAL </option>
  <option value="REGIUS VAN">REGIUS VAN </option>
  <option value="REGIUS WAGON ">REGIUS WAGON </option>
  <option value="REGIUSACE COMMUTER ">REGIUSACE COMMUTER </option>
  <option value="REGIUSACE VAN">REGIUSACE VAN </option>
  <option value="RENAULT OTHERS">RENAULT OTHERS </option>
  <option value="RENEGADE">RENEGADE </option>
  <option value="RETONA">RETONA </option>
  <option value="REVIEW">REVIEW </option>
  <option value="REX">REX </option>
  <option value="REXTON">REXTON </option>
  <option value="REXTON SPORTS ">REXTON SPORTS </option>
  <option value="REXTON SPORTS KHAN">REXTON SPORTS KHAN </option>
  <option value="REXTON W ">REXTON W </option>
  <option value="RIO">RIO </option>
  <option value="RNESSA">RNESSA </option>
  <option value="ROADSTER">ROADSTER </option>
  <option value="ROCKY">ROCKY </option>
  <option value="RODEO">RODEO </option>
  <option value="RODIUS">RODIUS </option>
  <option value="ROGUE">ROGUE </option>
  <option value="ROLLS-ROYCE OTHERS">ROLLS-ROYCE OTHERS </option>
  <option value="RONGGUANG TRUCK">RONGGUANG TRUCK </option>
  <option value="ROOMY">ROOMY </option>
  <option value="ROOX">ROOX</option>
  <option value="ROSA">ROSA </option>
  <option value="ROVER OTHERS">ROVER OTHERS </option>
  <option value="RS Q3">RS Q3 </option>
  <option value="RS Q8">RS Q8 </option>
  <option value="RS4">RS4 </option>
  <option value="RS5">RS5 </option>
  <option value="RS6">RS6 </option>
  <option value="RS7">RS7 </option>
  <option value="RUSH">RUSH </option>
  <option value="RVR">RVR </option>
  <option value="RX">RX</option>
  <option value="RX-7">RX-7 </option>
  <option value="RX-8">RX-8</option>
  <option value="RYOGA">RYOGA </option>
  <option value="S'ELEGA">S'ELEGA </option>
  <option value="S-10 ">S-10 </option>
  <option value="S-CLASS">S-CLASS</option>
  <option value="S-MAX">S-MAX </option>
  <option value="S-MX">S-MX </option>
  <option value="S-TYPE">S-TYPE </option>
  <option value="S130">S130 </option>
  <option value="S2000">S2000 </option>
  <option value="S3">S3 </option>
  <option value="S4">S4 </option>
  <option value="S40">S40 </option>
  <option value="S5">S5 </option>
  <option value="S6">S6 </option>
  <option value="S60">S60</option>
  <option value="S660">S660 </option>
  <option value=">S7 SPORTBACK">S7 SPORTBACK </option>
  <option value="S70">S70 </option>
  <option value="S8">S8 </option>
  <option value="S80">S80 </option>
  <option value="S90">S90 </option>
  <option value="SABER">SABER </option>
  <option value="SAFARI">SAFARI </option>
  <option value="SAGITAR">SAGITAR </option>
  <option value="SAI">SAI</option>
  <option value="SAMBAR">SAMBAR </option>
  <option value="SAMBAR TRUCK ">SAMBAR TRUCK </option>
  <option value="SANTA FE">SANTA FE </option>
  <option value="SANTA FE CM">SANTA FE CM </option>
  <option value="SANTA FE DM">SANTA FE DM </option>
  <option value="SANTA FE THE PRIME ">SANTA FE THE PRIME </option>
  <option value="SANTA FE THE STYLE ">SANTA FE THE STYLE </option>
  <option value="SANTA FE TM ">SANTA FE TM </option>
  <option value="SANTANA">SANTANA </option>
  <option value="SAVANA">SAVANA </option>
  <option value="SAVANNA RX-7 ">SAVANNA RX-7 </option>
  <option value="SAVEIRO">SAVEIRO </option>
  <option value="SAXO">SAXO </option>
  <option value="SC">SC </option>
  <option value="SCANIA OTHERS">SCANIA OTHERS </option>
  <option value="SCEPTER WAGON ">SCEPTER WAGON </option>
  <option value="SCIROCCO">SCIROCCO </option>
  <option value="SCRUM">SCRUM </option>
  <option value="SCRUM TRUCK">SCRUM TRUCK </option>
  <option value="SCRUM VAN">SCRUM VAN </option>
  <option value="SCRUM WAGON">SCRUM WAGON </option>
  <option value="SEBRING">SEBRING </option>
  <option value="SEDONA">SEDONA </option>
  <option value="SELTOS">SELTOS </option>
  <option value="SENTIA">SENTIA </option>
  <option value="SENTRA">SENTRA </option>
  <option value="SEQUOIA">SEQUOIA </option>
  <option value="SERA">SERA </option>
  <option value="SERENA">SERENA </option>
  <option value="SEVILLE">SEVILLE </option>
  <option value="SHARAN">SHARAN </option>
  <option value="SHOGUN">SHOGUN </option>
  <option value="SHUTTLE">SHUTTLE </option>
  <option value="SIENNA">SIENNA </option>
  <option value="SIENTA">SIENTA</option>
  <option value="SIERRA">SIERRA </option>
  <option value="SIGNUM">SIGNUM </option>
  <option value="SILVER SERAPH">SILVER SERAPH </option>
  <option value="SILVERADO">SILVERADO </option>
  <option value="SILVIA">SILVIA </option>
  <option value="SKODA OTHERS">SKODA OTHERS </option>
  <option value="SKYLINE">SKYLINE </option>
  <option value="SKYLINE COUPE">SKYLINE COUPE </option>
  <option value="SKYLINE CROSSOVER">SKYLINE CROSSOVER </option>
  <option value="SKYLINE GT-R">SKYLINE GT-R </option>
  <option value="SKYLINE VAN">SKYLINE VAN </option>
  <option value="SKYLINE WAGON">SKYLINE WAGON </option>
  <option value="SL">SL </option>
  <option value="SL-CLASS">SL-CLASS </option>
  <option value="SL10">SL10 </option>
  <option value="SLC">SLC </option>
  <option value="SLK">SLK </option>
  <option value="SLS AMG">SLS AMG </option>
  <option value="SM3">SM3 </option>
  <option value="SM5">SM5 </option>
  <option value="SM6">SM6 </option>
  <option value="SM7">SM7 </option>
  <option value="SMART">SMART </option>
  <option value="SMART K">SMART K </option>
  <option value="SMART OTHERS">SMART OTHERS </option>
  <option value="SOARER">SOARER </option>
  <option value="SOLATI">SOLATI </option>
  <option value="SOLIO">SOLIO </option>
  <option value="SOLIO BANDIT">SOLIO BANDIT</option>
  <option value="SONATA">SONATA </option>
  <option value="SONATA DN8 ">SONATA DN8 </option>
  <option value="SONATA N LINE ">SONATA N LINE </option>
  <option value="SONATA NEW RISE">SONATA NEW RISE </option>
  <option value="SONATA THE BRILLIAN">SONATA THE BRILLIANT </option>
  <option value="SONG">SONG </option>
  <option value="SONIC">SONIC </option>
  <option value="SONICA">SONICA </option>
  <option value="SORENTO">SORENTO </option>
  <option value="SORENTO MQ4">SORENTO MQ4 </option>
  <option value="SORENTO R">SORENTO R </option>
  <option value="SORENTO THE MASTER ">SORENTO THE MASTER </option>
  <option value="SOUL">SOUL </option>
  <option value="SOVEREIGN">SOVEREIGN </option>
  <option value="SPACE ARROW">SPACE ARROW </option>
  <option value="SPACE RUNNER RM ">SPACE RUNNER RM </option>
  <option value="SPACIA">SPACIA </option>
  <option value="SPADE">SPADE </option>
  <option value="SPARK">SPARK </option>
  <option value="SPARKY">SPARKY </option>
  <option value="SPEEDSTER">SPEEDSTER </option>
  <option value="SPIANO">SPIANO </option>
  <option value="SPIDER">SPIDER </option>
  <option value="SPLASH">SPLASH </option>
  <option value="SPORTAGE">SPORTAGE</option>
  <option value="SPORTAGE 5TH GENERATION ">SPORTAGE 5TH GENERATION </option>
  <option value="SPORTAGE R">SPORTAGE R </option>
  <option value="SPORTAGE THE BOLD">SPORTAGE THE BOLD </option>
  <option value="SPORTRIDER">SPORTRIDER </option>
  <option value="SPORTWAGON">SPORTWAGON </option>
  <option value="SPRINTER">SPRINTER </option>
  <option value="SPRINTER CARIB ">SPRINTER CARIB </option>
  <option value="SPRINTER MARINO ">SPRINTER MARINO </option>
  <option value="SPRINTER SEDAN">SPRINTER SEDAN </option>
  <option value="SPRINTER TRUENO">SPRINTER TRUENO </option>
  <option value="SPRINTER VAN">SPRINTER VAN </option>
  <option value="SQ2">SQ2 </option>
  <option value="SQ5">SQ5 </option>
  <option value="SQ8">SQ8 </option>
  <option value="SRX">SRX </option>
  <option value="SRX CROSSOVER">SRX CROSSOVER </option>
  <option value="STAGEA">STAGEA </option>
  <option value="STAREX">STAREX </option>
  <option value="STARIA">STARIA</option>
  <option value="STARION">STARION </option>
  <option value="STARLET">STARLET </option>
  <option value="STAVIC">STAVIC </option>
  <option value="STELLA">STELLA</option>
  <option value="STELVIO">STELVIO </option>
  <option value="STEP VAN ">STEP VAN </option>
  <option value="STEP WGN">STEP WGN </option>
  <option value="STINGER">STINGER </option>
  <option value="STONIC">STONIC </option>
  <option value="STORIA">STORIA </option>
  <option value="STRADA">STRADA </option>
  <option value="STRALIS">STRALIS </option>
  <option value="STREAM">STREAM </option>
  <option value="STREET">STREET </option>
  <option value="STS">STS </option>
  <option value="SUBARU 360">SUBARU 360 </option>
  <option value="SUBARU OTHERS">SUBARU OTHERS </option>
  <option value="SUBURBAN">SUBURBAN </option>
  <option value="SUCCEED">SUCCEED </option>
  <option value="SUCCEED VAN">SUCCEED VAN </option>
  <option value="SUCCEED WAGON">SUCCEED WAGON </option>
  <option value="SUMITOMO OTHERS ">SUMITOMO OTHERS </option>
  <option value="SUNNY">SUNNY </option>
  <option value="SUNNY CALIFORNIA">SUNNY CALIFORNIA </option>
  <option value="SUNNY TRUCK">SUNNY TRUCK </option>
  <option value="SUNSHINE">SUNSHINE </option>
  <option value="SUPER CARRY ">SUPER CARRY </option>
  <option value="SUPER DOLPHIN">SUPER DOLPHIN </option>
  <option value="SUPER DOLPHIN PROFIA">SUPER DOLPHIN PROFIA </option>
  <option value="SUPER GREAT">SUPER GREAT </option>
  <option value="SUPRA">SUPRA </option>
  <option value="SUZUKI OTHERS">SUZUKI OTHERS </option>
  <option value="SWEDEN OTHERS">SWEDEN OTHERS </option>
  <option value="SWIFT">SWIFT </option>
  <option value="SX4">SX4 </option>
  <option value="SX4 S-CROSS">SX4 S-CROSS </option>
  <option value="SYLPHY">SYLPHY</option>
  <option value="T-CROSS">T-CROSS </option>
  <option value="T-ROC">T-ROC</option>
  <option value="T100">T100 </option>
  <option value="TACOMA">TACOMA </option>
  <option value="TAFT">TAFT</option>
  <option value="TAHOE">TAHOE </option>
  <option value="TAKEUCHI OTHERS">TAKEUCHI OTHERS </option>
  <option value="TANG">TANG </option>
  <option value="TANK">TANK </option>
  <option value="TANTO">TANTO </option>
  <option value="TANTO CUSTOM">TANTO CUSTOM </option>
  <option value="TANTO EXE">TANTO EXE </option>
  <option value="TAURUS">TAURUS </option>
  <option value="TAYRON">TAYRON </option>
  <option value="TB250">TB250 </option>
  <option value="TEANA">TEANA </option>
  <option value="TERCEL">TERCEL </option>
  <option value="TERIOS KID">TERIOS KID </option>
  <option value="TERRACAN">TERRACAN </option>
  <option value="TERRANO">TERRANO </option>
  <option value="TERRANO REGULUS">TERRANO REGULUS </option>
  <option value="TESTAROSSA">TESTAROSSA </option>
  <option value="TGA">TGA </option>
  <option value="TGL">TGL </option>
  <option value="TGM">TGM </option>
  <option value="TGS">TGS </option>
  <option value="TGX">TGX </option>
  <option value="THATS">THATS </option>
  <option value="THE ALL NEW NIRO ">THE ALL NEW NIRO </option>
  <option value="THE ALL NEW TUCSON ">THE ALL NEW TUCSON </option>
  <option value="THE BEETLE">THE BEETLE </option>
  <option value="THE NEW AVANTE">THE NEW AVANTE </option>
  <option value="THE NEW CARNIVAL">THE NEW CARNIVAL</option>
  <option value="THE NEW GRANDEUR IG">THE NEW GRANDEUR IG </option>
  <option value="THE NEW K3">THE NEW K3 </option>
  <option value="THE NEW K3 2ND GENERATION">THE NEW K3 2ND GENERATION </option>
  <option value="THE NEW K5">THE NEW K5 </option>
  <option value="THE NEW K7">THE NEW K7 </option>
  <option value="THE NEW K9">THE NEW K9 </option>
  <option value="THE NEW MALIBU">THE NEW MALIBU </option>
  <option value="THE NEW MAXCRUZ">THE NEW MAXCRUZ </option>
  <option value="THE NEW MOHAVE">THE NEW MOHAVE </option>
  <option value="THE NEW MORNING">THE NEW MORNING </option>
  <option value="THE NEW NIRO">THE NEW NIRO </option>
  <option value="THE NEW SANTA FE">THE NEW SANTA FE </option>
  <option value="THE NEW SORENTO">THE NEW SORENTO </option>
  <option value="THE NEW SPORTAGE R">THE NEW SPORTAGE R </option>
  <option value="THE NEW TRAX ">THE NEW TRAX </option>
  <option value="THE NEW VELOSTER">THE NEW VELOSTER </option>
  <option value="THE SUV SPORTAGE">THE SUV SPORTAGE  </option>
  <option value="THOR">THOR </option>
  <option value="TIBURON">TIBURON </option>
  <option value="TIFFIN OTHERS ">TIFFIN OTHERS </option>
  <option value="TIGUAN">TIGUAN </option>
  <option value="TIGUAN ALLSPACE">TIGUAN ALLSPACE </option>
  <option value="TIIDA">TIIDA </option>
  <option value="TIIDA LATIO">TIIDA LATIO </option>
  <option value="TIPO">TIPO </option>
  <option value="TITAN">TITAN </option>
  <option value="TITAN DASH">TITAN DASH </option>
  <option value="TIVOLI">TIVOLI</option>
  <option value="TL">TL </option>
  <option value="TOLEDO">TOLEDO </option>
  <option value="TOPPO">TOPPO </option>
  <option value="TOPPO BJ">TOPPO BJ </option>
  <option value="TORNADO">TORNADO </option>
  <option value="TORNEO">TORNEO </option>
  <option value="TOSCA (EPICA)">TOSCA (EPICA) </option>
  <option value="TOUAREG">TOUAREG </option>
  <option value="TOURAN">TOURAN </option>
  <option value="TOURING HIACE">TOURING HIACE </option>
  <option value="TOWN BOX">TOWN BOX </option>
  <option value="TOWN BOX WIDE">TOWN BOX WIDE </option>
  <option value="TOWN CAR">TOWN CAR </option>
  <option value="TOWNACE NOAH ">TOWNACE NOAH </option>
  <option value="TOWNACE TRUCK">TOWNACE TRUCK </option>
  <option value="TOWNACE VAN">TOWNACE VAN </option>
  <option value="TOWNACE WAGON">TOWNACE WAGON </option>
  <option value="TOYOACE">TOYOACE </option>
  <option value="TOYOACE ROUTE VAN">TOYOACE ROUTE VAN </option>
  <option value="TOYOACE URBAN SUPPORTER">TOYOACE URBAN SUPPORTER </option>
  <option value="TOYOTA OTHERS">TOYOTA OTHERS </option>
  <option value="TRACKER">TRACKER </option>
  <option value="TRAFIC">TRAFIC </option>
  <option value="v">TRAILBLAZER </option>
  <option value="TRAKKER">TRAKKER </option>
  <option value="TRANSIT">TRANSIT </option>
  <option value="TRANSPORTER">TRANSPORTER </option>
  <option value="TRAVERSE">TRAVERSE </option>
  <option value="TRAX">TRAX</option>
  <option value="TREZIA">TREZIA </option>
  <option value="TRITON">TRITON </option>
  <option value="TRIUMPH OTHERS">TRIUMPH OTHERS </option>
  <option value="TRUCK">TRUCK </option>
  <option value="TT">TT </option>
  <option value="TT ROADSTER">TT ROADSTER </option>
  <option value="TTS">TTS </option>
  <option value="TUCSON">TUCSON </option>
  <option value="TUCSON IX">TUCSON IX </option>
  <option value="TUNDRA">TUNDRA </option>
  <option value="TURBO">TURBO </option>
  <option value="TUSCANI">TUSCANI </option>
  <option value="TWIN">TWIN </option>
  <option value="TWINGO">TWINGO </option>
  <option value="TWIZY">TWIZY </option>
  <option value="TYPE2">TYPE2 </option>
  <option value="U.S.A. OTHERS ">U.S.A. OTHERS </option>
  <option value="U17">U17 </option>
  <option value="UD">UD </option>
  <option value="UNI-K">UNI-K </option>
  <option value="UNIMOG">UNIMOG </option>
  <option value="UNIVERSE">UNIVERSE </option>
  <option value="UNO">UNO </option>
  <option value="UP!">UP! </option>
  <option value="URUS">URUS </option>
  <option value="URVAN">URVAN </option>
  <option value="URVAN COMMUTER">URVAN COMMUTER </option>
  <option value="UX">UX </option>
  <option value="V-CLASS ">V-CLASS </option>
  <option value="V12 VANQUISH">V12 VANQUISH </option>
  <option value="V40">V40 </option>
  <option value="V50">V50 </option>
  <option value="V60">V60 </option>
  <option value="V70">V70 </option>
  <option value="V8">V8 </option>
  <option value="V8 VANTAGE">V8 VANTAGE </option>
  <option value="V90">V90 </option>
  <option value="VAMOS">VAMOS </option>
  <option value="VAMOS HOBIO">VAMOS HOBIO </option>
  <option value="VAMOS HOBIO PRO">VAMOS HOBIO PRO </option>
  <option value="VANAGON">VANAGON </option>
  <option value="VANAGON PLAS">VANDEN PLAS </option>
  <option value="VANDURA">VANDURA </option>
  <option value="VANEO">VANEO </option>
  <option value="VANETTE TRUCK">VANETTE TRUCK</option>
  <option value="VANETTE VAN ">VANETTE VAN </option>
  <option value="VANGUARD">VANGUARD </option>
  <option value="VANTAGE">VANTAGE </option>
  <option value="VECTRA">VECTRA </option>
  <option value="VEHICROSS">VEHICROSS </option>
  <option value="VELLFIRE">VELLFIRE </option>
  <option value="VELLFIRE HYBRID">VELLFIRE HYBRID </option>
  <option value="VELOSTER">VELOSTER </option>
  <option value="VELOSTER JS">VELOSTER JS </option>
  <option value="VENTO">VENTO </option>
  <option value="VENTURE">VENTURE </option>
  <option value="VENUE">VENUE </option>
  <option value="VERACRUZ">VERACRUZ </option>
  <option value="VERISA">VERISA </option>
  <option value="VERNA">VERNA </option>
  <option value="VEROSSA">VEROSSA </option>
  <option value="VERSA">VERSA </option>
  <option value="VEZEL">VEZEL </option>
  <option value="VIANO">VIANO </option>
  <option value="VIEW C2">VIEW C2 </option>
  <option value="VIEWT">VIEWT </option>
  <option value="VIGOR">VIGOR </option>
  <option value="VIOS">VIOS </option>
  <option value="VISTA ARDEO">VISTA ARDEO </option>
  <option value="VISTA SEDAN">VISTA SEDAN </option>
  <option value="VITA">VITA </option>
  <option value="VITARA">VITARA </option>
  <option value="VITZ">VITZ </option>
  <option value="VITZ HYBRID">VITZ HYBRID </option>
  <option value="VIVIO">VIVIO </option>
  <option value="VOLKSWAGEN OTHERS">VOLKSWAGEN OTHERS </option>
  <option value="VOLTZ">VOLTZ </option>
  <option value="VOLVO OTHERS">VOLVO OTHERS </option>
  <option value="VOXY">VOXY</option>
  <option value="VOXY HYBRID">VOXY HYBRID </option>
  <option value="VOYAGER">VOYAGER </option>
  <option value="WAGON R">WAGON R </option>
  <option value="WAGON R SOLIO">WAGON R SOLIO </option>
  <option value="WAGON R WIDE">WAGON R WIDE </option>
  <option value="WAKE">WAKE </option>
  <option value="WILL CYPHA">WILL CYPHA </option>
  <option value="WILL VI">WILL VI </option>
  <option value="WILL VS">WILL VS </option>
  <option value="WIND">WIND </option>
  <option value="WINDOM">WINDOM </option>
  <option value="WINGROAD">WINGROAD </option>
  <option value="WINSTORM (CAPTIVA)">WINSTORM (CAPTIVA) </option>
  <option value="WINSTORM MAXX">WINSTORM MAXX </option>
  <option value="WISH">WISH </option>
  <option value="WIZARD">WIZARD </option>
  <option value="WRANGLER">WRANGLER </option>
  <option value="WRX S4">WRX S4 </option>
  <option value="RX STI">WRX STI</option>
  <option value="X BEE">X BEE </option>
  <option value="X-90">X-90 </option>
  <option value="X-CLASS">X-CLASS </option>
  <option value="X-TRAIL">X-TRAIL </option>
  <option value="X-TRAIL HYBRID ">X-TRAIL HYBRID </option>
  <option value="X-TYPE">X-TYPE </option>
  <option value="X1">X1 </option>
  <option value="X2">X2 </option>
  <option value="X3">X3 </option>
  <option value="X4">X4 </option>
  <option value="X5">X5 </option>
  <option value="X6">X6 </option>
  <option value="X7">X7 </option>
  <option value="X70">X70 </option>
  <option value="X70 PLUS">X70 PLUS </option>
  <option value="X70S">X70S </option>
  <option value="X90 PLUS">X90 PLUS </option>
  <option value="XANTIA">XANTIA </option>
  <option value="XC40">XC40 </option>
  <option value="XC60">XC60 </option>
  <option value="XC7-SR07">XC7-SR07 </option>
  <option value="XC70">XC70 </option>
  <option value="XC90">XC90 </option>
  <option value="XE">XE </option>
  <option value="XF">XF </option>
  <option value="XF105">XF105 </option>
  <option value="XF95">XF95 </option>
  <option value="XJ SERIES">XJ SERIES </option>
  <option value="XJ-S">XJ-S </option>
  <option value="XK SERIES">XK SERIES </option>
  <option value="XLR">XLR </option>
  <option value="XM">XM </option>
  <option value="XM3">XM3 </option>
  <option value="XMQ6500">XMQ6500 </option>
  <option value="XT4">XT4 </option>
  <option value="XT5">XT5 </option>
  <option value="XT6">XT6 </option>
  <option value="XTERRA">XTERRA </option>
  <option value="XV">XV </option>
  <option value="XV HYBRID ">XV HYBRID </option>
  <option value="YANMAR OTHERS">YANMAR OTHERS </option>
  <option value="YARIS">YARIS </option>
  <option value="YARIS CROSS">YARIS CROSS </option>
  <option value="YDM-F14F">YDM-F14F </option>
  <option value="YDM-F18D">YDM-F18D </option>
  <option value="YDN-F188A ">YDN-F188A </option>
  <option value="YF SONATA">YF SONATA </option>
  <option value="YPSILON">YPSILON </option>
  <option value="YRV">YRV </option>
  <option value="YUAN">YUAN </option>
  <option value="YUKON">YUKON </option>
  <option value="Z">Z </option>
  <option value="Z3">Z3 </option>
  <option value="Z4">Z4 </option>
  <option value="Z8">Z8 </option>
  <option value="ZAF-NJ1B1">ZAF-NJ1B1 </option>
  <option value="ZAFIRA">ZAFIRA </option>
  <option value="ZEST">ZEST </option>
  <option value="ZEST SPARK ">ZEST SPARK </option>
  <option value="ZHENGCHENG">ZHENGCHENG </option>
  <option value="ZHENGTU">ZHENGTU </option>
  <option value="ZR-V">ZR-V </option>
  <option value="ZS">ZS </option>
  <option value="ZT">ZT </option>
  
                          </select>
               </div>
        <div className="col-12 text-left mb-3" >
      
      <label className="checkout-field-label d-block" for="price">Country<span className="important-star">*</span></label>
      <select name="tp_country_id" value={newReview.country}  onChange={(e)=>setNewReview({...newReview,country:e.target.value})}  id="tp_country_id" autocomplete="off" style={{width:'95%'}}>
                                          <option value="">select</option>
                                          <option value="Zambia">Zambia</option>
  <option value="D.R.Congo">D.R.Congo</option>
  <option value="Mozambique">Mozambique</option>
  <option value="Zimbabwe">Zimbabwe</option>
  <option value="Uganda">Uganda</option>
  <option value="Tanzania">Tanzania</option>
  <option value="Malawi">Malawi</option>
  <option value="Burundi">Burundi</option>
  <option value="Lesotho">Lesotho</option>
  <option value="South Sudan">South Sudan</option>
  <option value="Botswana">Botswana</option>
  <option value="Kenya">Kenya</option>
  <option value="Republic of South Africa">Republic of South Africa</option>
  <option value="Djibouti">Djibouti</option>
  <option value="Ethiopia">Ethiopia</option>
  <option value="Angola">Angola</option>
  <option value="Benin">Benin</option>
  <option value="Cameroon">Cameroon</option>
  <option value="Gabon">Gabon</option>
  <option value="Ghana">Ghana</option>
  <option value="Guinea">Guinea</option>
  <option value="Madagascar">Madagascar</option>
  <option value="Mali">Mali</option>
  <option value="Namibia">Namibia</option>
  <option value="Nigeria">Nigeria</option>
  <option value="Rwanda">Rwanda</option>
  <option value="Sierra Leone">Sierra Leone</option>
  <option value="Sudan">Sudan</option>
  <option value="Algeria">Algeria</option>
  <option value="Canary Islands">Canary Islands</option>
  <option value="Cape Verde">Cape Verde</option>
  <option value="Central African Republic">Central African Republic</option>
  <option value="Chad">Chad</option>
  <option value="Equatorial Guinea">Equatorial Guinea</option>
  <option value="Eritrea">Eritrea</option>
  <option value="Gambia">Gambia</option>
  <option value="Liberia">Liberia</option>
  <option value="Mahe">Mahe</option>
  <option value="Maldives">Maldives</option>
  <option value="Mauritania">Mauritania</option>
  <option value="Mayotte">Mayotte</option>
  <option value="Morocco">Morocco</option>
  <option value="Niger">Niger</option>
  <option value="Republic of Guinea-Bissau">Republic of Guinea-Bissau</option>
  <option value="Republic of the Congo">Republic of the Congo</option>
  <option value="Reunion">Reunion</option>
  <option value="Saint Helena">Saint Helena</option>
  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
  <option value="Seychelles">Seychelles</option>
  <option value="Swaziland">Swaziland</option>
  <option value="Tunisia">Tunisia</option>
  <option value="Afghanistan">Afghanistan</option>
  <option value="Albania">Albania</option>
  <option value="American Samoa">American Samoa</option>
  <option value="Anguilla">Anguilla</option>
  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
  <option value="Argentina">Argentina</option>
  <option value="Armenia">Armenia</option>
  <option value="Aruba">Aruba</option>
  <option value="Australia">Australia</option>
  <option value="Austria">Austria</option>
  <option value="Azerbaijan">Azerbaijan</option>
  <option value="Bahamas">Bahamas</option>
  <option value="Bahrain">Bahrain</option>
  <option value="Bangladesh">Bangladesh</option>
  <option value="Barbados">Barbados</option>
  <option value="Belgium">Belgium</option>
  <option value="Belize">Belize</option>
  <option value="Bermuda">Bermuda</option>
  <option value="Bhutan">Bhutan</option>
  <option value="Bolivia">Bolivia</option>
  <option value="Bonaire/Netherlands Antilles">Bonaire/Netherlands Antilles</option>
  <option value="Brazil">Brazil</option>
  <option value="British Virgin Islands">British Virgin Islands</option>
  <option value="Brunei Darussalam">Brunei Darussalam</option>
  <option value="Bulgaria">Bulgaria</option>
  <option value="Burkina Faso">Burkina Faso</option>
  <option value="Cambodia">Cambodia</option>
  <option value="Canada">Canada</option>
  <option value="Cayman Islands">Cayman Islands</option>
  <option value="Chile">Chile</option>
  <option value="Colombia">Colombia</option>
  <option value="Commonwealth of Dominica">Commonwealth of Dominica</option>
  <option value="Comoros">Comoros</option>
  <option value="Cook Islands">Cook Islands</option>
  <option value="Costa Rica">Costa Rica</option>
  <option value="Cote D'Ivoire">Cote D'Ivoire</option>
  <option value="Croatia">Croatia</option>
  <option value="Cuba">Cuba</option>
  <option value="Curacao/Netherlands Antilles">Curacao/Netherlands Antilles</option>
  <option value="Cyprus">Cyprus</option>
  <option value="Czech republic">Czech republic</option>
  <option value="Denmark">Denmark</option>
  <option value="Dominican Republic">Dominican Republic</option>
  <option value="East Timor">East Timor</option>
  <option value="Ecuador">Ecuador</option>
  <option value="Egypt">Egypt</option>
  <option value="Estonia">Estonia</option>
  <option value="Fiji">Fiji</option>
  <option value="Finland">Finland</option>
  <option value="France">France</option>
  <option value="French Guiana">French Guiana</option>
  <option value="French Polynesia">French Polynesia</option>
  <option value="Georgia">Georgia</option>
  <option value="Germany">Germany</option>
  <option value="Greece">Greece</option>
  <option value="Grenada">Grenada</option>
  <option value="Guadeloupe">Guadeloupe</option>
  <option value="Guatemala">Guatemala</option>
  <option value="Guyana">Guyana</option>
  <option value="Haiti">Haiti</option>
  <option value="Honduras">Honduras</option>
  <option value="Hong Kong">Hong Kong</option>
  <option value="Hungary">Hungary</option>
  <option value="Iceland">Iceland</option>
  <option value="India">India</option>
  <option value="Indonesia">Indonesia</option>
  <option value="Iran">Iran</option>
  <option value="Iraq">Iraq</option>
  <option value="Ireland">Ireland</option>
  <option value="Israel">Israel</option>
  <option value="Italy">Italy</option>
  <option value="Jamaica">Jamaica</option>
  <option value="Japan">Japan</option>
  <option value="Jordan">Jordan</option>
  <option value="Kazakhstan">Kazakhstan</option>
  <option value="Kiribati">Kiribati</option>
  <option value="Korea">Korea</option>
  <option value="Kuwait">Kuwait</option>
  <option value="Kyrgyzstan">Kyrgyzstan</option>
  <option value="LAO PDR">LAO PDR</option>
  <option value="Latvia">Latvia</option>
  <option value="Lebanon">Lebanon</option>
  <option value="Libya">Libya</option>
  <option value="Lithuania">Lithuania</option>
  <option value="Luxembourg">Luxembourg</option>
  <option value="Macau">Macau</option>
  <option value="Malaysia">Malaysia</option>
  <option value="Marshall Islands">Marshall Islands</option>
  <option value="Martinique">Martinique</option>
  <option value="Mauritius">Mauritius</option>
  <option value="Mexico">Mexico</option>
  <option value="Micronesia">Micronesia</option>
  <option value="MOLDOVA">MOLDOVA</option>
  <option value="Mongolia">Mongolia</option>
  <option value="Montenegro">Montenegro</option>
  <option value="Montserrat">Montserrat</option>
  <option value="Myanmar">Myanmar</option>
  <option value="Nauru">Nauru</option>
  <option value="Nepal">Nepal</option>
  <option value="Netherlands">Netherlands</option>
  <option value="Netherlands Antilles">Netherlands Antilles</option>
  <option value="New Caledonia">New Caledonia</option>
  <option value="New Zealand">New Zealand</option>
  <option value="Niue">Niue</option>
  <option value="Norway">Norway</option>
  <option value="Oman">Oman</option>
  <option value="Pakistan">Pakistan</option>
  <option value="Palau">Palau</option>
  <option value="Panama">Panama</option>
  <option value="Papua New Guinea">Papua New Guinea</option>
  <option value="Paraguay">Paraguay</option>
  <option value="Peoples Republic of China">Peoples Republic of China</option>
  <option value="Peru">Peru</option>
  <option value="Philippines">Philippines</option>
  <option value="Poland">Poland</option>
  <option value="Portugal">Portugal</option>
  <option value="PUERTO RICO">PUERTO RICO</option>
  <option value="Qatar">Qatar</option>
  <option value="REPUBLIC OF BELARUS">REPUBLIC OF BELARUS</option>
  <option value="Republic of El Salvador">Republic of El Salvador</option>
  <option value="Republic of Macedonia">Republic of Macedonia</option>
  <option value="Republic of Nicaragua">Republic of Nicaragua</option>
  <option value="Romania">Romania</option>
  <option value="Russian Federation">Russian Federation</option>
  <option value="Saba Island">Saba Island</option>
  <option value="Saint Eustatius">Saint Eustatius </option>
  <option value="Saint Kitts And Nevis">Saint Kitts And Nevis</option>
  <option value="Samoa">Samoa</option>
  <option value="Saudi Arabia">Saudi Arabia</option>
  <option value="Senegal">Senegal</option>
  <option value="Serbia">Serbia</option>
  <option value="Singapore">Singapore</option>
  <option value="Slovak Republic">Slovak Republic</option>
  <option value="SLOVENIA">SLOVENIA</option>
  <option value="Solomon Islands">Solomon Islands</option>
  <option value="Somalia">Somalia</option>
  <option value="Spain">Spain</option>
  <option value="Sri Lanka">Sri Lanka</option>
  <option value="St.Barthelemy">St.Barthelemy</option>
  <option value="St.Croix">St.Croix</option>
  <option value="t.Lucia">St.Lucia</option>
  <option value="St.Maarte">St.Maarten</option>
  <option value="St.Thomas">St.Thomas</option>
  <option value="St.Vincent">St.Vincent</option>
  <option value="Suriname">Suriname</option>
  <option value="Sweden">Sweden</option>
  <option value="Switzerland">Switzerland</option>
  <option value="Syria">Syria</option>
  <option value="TAIWAN">TAIWAN</option>
  <option value="Tajikistan">Tajikistan</option>
  <option value="Thailand">Thailand</option>
  <option value="Togo">Togo</option>
  <option value="Tonga">Tonga</option>
  <option value="Tortola">Tortola</option>
  <option value="Trinidad And Tobago">Trinidad And Tobago</option>
  <option value="Turkey">Turkey</option>
  <option value="Turkmenistan">Turkmenistan</option>
  <option value="Turks And Caicos Islands">Turks And Caicos Islands</option>
  <option value="Tuvalu">Tuvalu</option>
  <option value="UAE">UAE</option>
  <option value="Ukraine">Ukraine</option>
  <option value="United Kingdom">United Kingdom</option>
  <option value="Uruguay">Uruguay</option>
  <option value="US VIRGIN ISLANDS">US VIRGIN ISLANDS</option>
  <option value="USA">USA</option>
  <option value="Uzbekistan">Uzbekistan</option>
  <option value="Vanuatu">Vanuatu</option>
  <option value="Venezuela">Venezuela</option>
  <option value="Vietnam">Vietnam</option>
  <option value="Wallis et Futuna">Wallis et Futuna</option>
  <option value="Yemen">Yemen</option>
  <option value="Somaliland">Somaliland</option>
  
                                      </select>
                                      </div>
        <div className="col-12 text-left mb-3">
      
      <label className="checkout-field-label d-block" for="price">star<span className="important-star">*</span></label>
        <input type="number" className="checkout-input-field w-95" value={newReview.star} onChange={(e)=>setNewReview({...newReview,star:e.target.value})} placeholder="star"  name="price"/>
        </div>
   
      </div>
      <div className="col-6">
        <div className="row">
            <div className="col-3"></div>
        <div className="text-center col-6 p-relative">
      <label className="checkout-field-label d-block text-left" for="productimage">Display Image <span className="important-star">*</span></label>
      <img className="w-95 m-auto previewImg" style={{height:'12rem'}} alt="preview image" src={displayImage}/>
      <input type="file"  name="productimage1" accept="image/*" onChange={(e)=>onImageChange(e,"diplayImg")} className="filetype w-95" style={{fontSize:'0.5rem',height:"12rem"}} />
    </div>
    <div className="col-3"></div>
        </div>

      </div>
      </div>
      <div className="row w-95 m-auto">
        <div className="col-6">
      <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="stars">date<span className="important-star">*</span></label>
      <input type="date" className="checkout-input-field w-95" value={newReview.date} onChange={(e)=>setNewReview({...newReview,date:e.target.value})} min="1" max="5" name="stars" />
      </div> 
      <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="stars">Description<span className="important-star">*</span></label>
      <textarea rows="4" cols="50" className="checkout-input-field w-95" value={newReview.description} onChange={(e)=>setNewReview({...newReview,description:e.target.value})} min="1" max="5" name="stars" />
      </div> 
     
        </div>
      </div>
      <div className='text-center w-70 m-auto'>
    <p className='text-center alert-txt mb-3'  style={{color:'red',marginBottom:'0',marginTop:'1rem'}} id='newproductUpdateTxt'></p>
 <button  className="w-20 mt- m-auto py-2 contact-form-btn" onClick={addNewReview}>add</button>
 </div>
   </div>)}
      </>)
}



export default NewReview;