import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

import tempImg from '../../assets/images/add-photo.png';
import LoadingSpinner from '../../component/LoadingSpinner.js';



const NewBlog = () =>{
    var [isLoading,setIsLoading] = React.useState(false);
    var [newBlog, setNewBlog] = React.useState({
     displayImage:'',title:'',date:'',description:'',writer:''
  })
  const [displayImage,setDisplayImage] = React.useState(tempImg);
  const onImageChange = (event,val) => {
   if (event.target.files && event.target.files[0]) {
    if (val == "diplayImg") {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setNewBlog({...newBlog,displayImage:event.target.files[0]});
    }
   }
  }
  
      let navigate = useNavigate();
  
  
  function addNewBlog(e) {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
  
        if (newBlog.displayImage && newBlog.writer && newBlog.description && newBlog.title && newBlog.date ) {
            formData.append("data",JSON.stringify(newBlog));
            formData.append("files",newBlog.displayImage);
           setTimeout(() => {    
             axios.post('https://backend.kbmjapan.net/add-blog',formData,{withCredentials:true})
              .then((res)=>{
                if (res.status == 200) {            
                  setIsLoading(false);
                     navigate('/');
                     window.location.reload();
                }
              })
              .catch((err)=>{
                console.log(err);
                setIsLoading(false);
              })
           }, 100);
        }else{
          setIsLoading(false);
          document.getElementById('newproductUpdateTxt').innerText = 'kindly fill the form properly...';
          setTimeout(() => {
            document.getElementById('newproductUpdateTxt').innerText = '';
          }, 2500);
        }
      
  }
  
      return(<>
      { isLoading ? (<LoadingSpinner/>):(
        <div><div className="page-head">
   <h2 className="text-center">Add Blog</h2>   
   </div>
      <div className="row w-95 m-auto mt-5">
    <div className="col-6">
    <div className="col-12 text-left mb-3">
      <label className="checkout-field-label d-block" for="productname">Title<span className="important-star">*</span></label>
      <input type="text" className="checkout-input-field w-95" value={newBlog.title} onChange={(e)=>setNewBlog({...newBlog,title:e.target.value})}   name="title" placeholder="title" />
      </div>
    <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="price">Written By / Writer Name<span className="important-star">*</span></label>
      <input type="text" className="checkout-input-field w-95" value={newBlog.writer} onChange={(e)=>setNewBlog({...newBlog,writer:e.target.value})}  name="By" placeholder="writer"/>
      </div>
      <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="stars">date<span className="important-star">*</span></label>
      <input type="date" className="checkout-input-field w-95" value={newBlog.date} onChange={(e)=>setNewBlog({...newBlog,date:e.target.value})} min="1" max="5" name="stars" />
      </div> 
      <div className="col-12 text-left mb-3">
    <label className="checkout-field-label d-block" for="stars">Description<span className="important-star">*</span></label>
      <textarea rows="20" cols="50" className="checkout-input-field w-95" value={newBlog.description} onChange={(e)=>setNewBlog({...newBlog,description:e.target.value})} min="1" max="5" name="stars" />
      </div> 
      </div>
      <div className="col-6">
        <div className="row">
            <div className="col-3"></div>
        <div className="text-center col-6 p-relative">
      <label className="checkout-field-label d-block text-left" for="productimage">Display Image <span className="important-star">*</span></label>
      <img className="w-95 m-auto previewImg" style={{height:'12rem'}} alt="preview image" src={displayImage}/>
      <input type="file"  name="productimage1" accept="image/*" onChange={(e)=>onImageChange(e,"diplayImg")} className="filetype w-95" style={{fontSize:'0.5rem',height:"12rem"}} />
    </div>
    <div className="col-3"></div>
        </div>

      </div>
      </div>
      <div className="row w-95 m-auto">
        <div className="col-6">
   
     
        </div>
      </div>
      <div className='text-center w-70 m-auto'>
    <p className='text-center alert-txt mb-3'  style={{color:'red',marginBottom:'0',marginTop:'1rem'}} id='newproductUpdateTxt'></p>
 <button  className="w-20 mt- m-auto py-2 contact-form-btn" onClick={addNewBlog}>add</button>
 </div>
   </div>)}
      </>)
}



export default NewBlog;