import React from 'react'
import {Routes, Route,BrowserRouter as Router , Navigate} from 'react-router-dom';
import Login from "./Login.js";
import axios from "axios";
import HeaderAdmin from '../../component/HeaderAdmin.js';
import AddProducts from './AddProducts.js';
import Footer from '../../component/Footer.js';
import NewProduct from './NewProduct.js';
import EditProduct from './EditProduct.js';
import LoadingSpinner from '../../component/LoadingSpinner.js';
import Reviews from './Reviews.js';
import NewReview from './NewReview.js';
import EditReview from './EditReview.js';
import FOB from './FOB.js';
import NewBlog from './NewBlog.js';
import Blogs from './Blogs.js';
import EditBlog from './EditBlog.js';
import NewAutoPart from './NewAutoPart.js';
import AutoParts from './AutoParts.js';
import EditAutoPart from './EditAutoPart.js';

const Index = ()=>{
    var [isAuth,setIsAuth] = React.useState(false);
    var [isLoading,setIsLoading] = React.useState(true);
  React.useEffect(()=>{
    axios.get('https://backend.kbmjapan.net/protected',{withCredentials:true})
    .then((res)=>{
      if (res.status === 200) {
        setIsAuth(true);
        setIsLoading(false);
      }
    })
    .catch((err)=>{
      setIsLoading(false);
    })
  },[])
return(<>
{
  isLoading ? (<LoadingSpinner/>):(
<Router>
<header>
   <HeaderAdmin/>
   </header>
 <Routes>
 <Route exact path="/login" element={isAuth?<Navigate to="/add-products" replace />:<Login/>}/>
 <Route exact path="/add-products" element={isAuth?<AddProducts/>:<Login/>}/>
 <Route exact path="/" element={isAuth?<AddProducts/>:<Login/>}/>
 <Route exact path="/new-product" element={isAuth?<NewProduct/>:<Login/>}/>
 <Route exact path="/edit-product/:id" element={isAuth?<EditProduct/>:<Login/>}/>
 <Route exact path="/add-auto-part" element={isAuth?<AutoParts/>:<Login/>}/>
 <Route exact path="/new-auto-part" element={isAuth?<NewAutoPart/>:<Login/>}/>
 <Route exact path="/edit-auto-part/:id" element={isAuth?<EditAutoPart/>:<Login/>}/>
 <Route exact path="/reviews" element={isAuth?<Reviews/>:<Login/>}/>
 <Route exact path="/new-review" element={isAuth?<NewReview/>:<Login/>}/>
 <Route exact path="/edit-review/:id" element={isAuth?<EditReview/>:<Login/>}/>
 <Route exact path="/fob" element={isAuth?<FOB/>:<Login/>}/>
 <Route exact path="/blogs" element={isAuth?<Blogs/>:<Login/>}/>
 <Route exact path="/new-blogs" element={isAuth?<NewBlog/>:<Login/>}/>
 <Route exact path="/edit-blog/:id" element={isAuth?<EditBlog/>:<Login/>}/>
 </Routes>
 <footer>
  <Footer/>
 </footer>
 </Router>
  )}
</>)
}


export default Index;