import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useNavigate} from 'react-router-dom'

// images

import logo from '../assets/images/Logo1.png'
import axios from 'axios';

function activeNavItem(path) {
  var ele = document.querySelectorAll('.nav-link');
  ele.forEach((element) => {
   element.classList.remove('active');
  });
  // if (path == '/order-list') {
  //  ele[0].classList.add('active');
  // }else 
  if(path == '/add-products' || path == '/'){
   ele[0].classList.add('active');
  }else if(path == '/reviews'){
   ele[1].classList.add('active');
  }else if(path == '/subscriptions'){
   ele[2].classList.add('active');
  }else if(path == '/fob'){
    ele[2].classList.add('active');
   }else if(path == '/blogs'){
    ele[3].classList.add('active');
   }else if(path == '/add-auto-part'){
    ele[4].classList.add('active');
   }
}

const HeaderAdmin=()=>{
  var [isAuth,setIsAuth] = React.useState(false);
React.useEffect(()=>{
  axios.get('https://backend.kbmjapan.net/protected',{withCredentials:true})
  .then((res)=>{
    if (res.status === 200) {
      setIsAuth(true);
    }else{
      setIsAuth(false);
    }
  })
  .catch((err)=>{
    console.log(err);
  })
},[])
  const navigate = useNavigate();
  const clickHandler=(id)=>{
   activeNavItem(`${id}`);
    navigate(`${id}`);
    window.location.reload();
  }

  function logoutHandler(e) {
    e.preventDefault();
    axios.get('https://backend.kbmjapan.net/logout',{withCredentials:true})
    .then((res)=>{
      navigate('/login');
        window.location.reload();
    })
    .catch((err)=>console.log(err))
  }

  React.useEffect(()=>{
    var path = window.location.pathname;
    activeNavItem(path);
  },[])
    return(
        <div>
             <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid style={{width:'95%'}}>
       <Navbar.Brand onClick={(e)=>clickHandler('/add-products')}><img style={{width: '25%'}} src='https://kbmjapan.net/static/app/images/kbm.jpg' alt=''/> </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="m-auto my-2 my-lg-0 gap-4"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
         <Nav.Link className='active'  onClick={(e)=>clickHandler('/add-products')}>Products</Nav.Link>
            <Nav.Link onClick={(e)=>clickHandler('/reviews')}>Reviews</Nav.Link>
            <Nav.Link onClick={(e)=>clickHandler('/fob')}>Add M3 Price</Nav.Link>
            <Nav.Link onClick={(e)=>clickHandler('/blogs')}>Blogs</Nav.Link>
            <Nav.Link onClick={(e)=>clickHandler('/add-auto-part')}>Auto Parts</Nav.Link>
            {/* <Nav.Link onClick={(e)=>clickHandler('/subscriptions')}>Subscriptions</Nav.Link> */}
          </Nav>
          <Form className="d-flex gap-3">
          {isAuth?<div><button className='' style={{color:'black'}} onClick={logoutHandler}>Logout</button></div>:''}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </div>
    )
}

export default HeaderAdmin;